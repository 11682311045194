import React from "react";
import { Consumer } from "../../Context";
import { Link } from "react-router-dom";
import Poster from "./imgs/our-work-poster.webp";
import ourworkVideo from './video/our-work.mp4'
const PortfolioVideo = () => (
  <Consumer>
    {context => (
      <Link
        to="all-portfolios.php"
        className="d-block video-wrapper"
        onMouseEnter={e =>
          context.handleMouseEnter(e, "big", "Click to visit Portfolio")
        }
        onMouseLeave={context.handleMouseLeave}
        onClick={() => context.isMenuOpen && context.handleMenu()}
      >
        <video
          autoPlay={!context.touchDevice}
          loop
          muted
          poster={Poster}
          preload="none"
          controls={context.touchDevice}
          className="w-100 d-block"
        >
          <source
            src={ourworkVideo}
            type="video/mp4"
          />
          No Support
        </video>
      </Link>
    )}
  </Consumer>
);

export default PortfolioVideo;
