import React from "react";
import "./GridLines.scss";
import {withRouter} from "react-router-dom";

class GridLines extends React.Component {
  render() {
    const { location } = this.props;
    const isCaseStudy = location?.pathname.split('/')[1] === 'all-portfolios.php'
    const isPortfolioDetail = location?.pathname.split('/')[1] === 'portfolio'
    const {isGridForceVisiblity} = this.props;
    const gridLines = [];
    for (let i = 0; i < 12; i++) {
      gridLines.push(<div className="col p-0" key={i} />);
    }
    return (
      <div
          className={`container-fluid grid-line-wrapper ${(isCaseStudy || isPortfolioDetail) ? isGridForceVisiblity ? '' : '' : ''}`}>
        <div className="row">{gridLines}</div>
      </div>
    );
  }
}

export default withRouter(GridLines);
