import React from "react";
import { Link } from "react-router-dom";
import { Consumer } from "../../Context";

class ServicesTab extends React.Component {
  state = {
    height: 0
  };
  componentDidMount() {
    const height = document
      .getElementById("servicesTab")
      .getBoundingClientRect().height;
    this.setState({ height });
  }
  render() {
    return (
      <Consumer>
        {context => (
          <div className="tab-detail" style={{ height: this.state.height }}>
            <div className="row mx-0 mt-5" id="servicesTab">
              <div className="col-12 col-xl-4 px-0">
                <div className="row mx-0">
                  <div className="col-12 col-md-6 col-xl-12 px-0 mb-5 overflow-hidden">
                    <h6 className="uppercase-text list-title">
                      <Link
                        to="/mobile-app-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Mobile App Development
                      </Link>
                    </h6>
                    <ul className="ul-list">
                      <li>
                        <Link
                          to="/iphone-application-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          iPhone App Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/android-application-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Android App Development
                        </Link>
                      </li>
                      {/*<li>
                        <Link
                          to="/ipad-application-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          iPad App Development
                        </Link>
                      </li>*/}
                      <li>
                        <Link
                          to="/swift-app-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Swift App Development
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12 px-0 mb-5 overflow-hidden">
                    <h6 className="uppercase-text list-title">
                      <p>Cross platform</p>
                    </h6>
                    <ul className="ul-list">
                      <li>
                        <Link
                          to="/react-native-app-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          React Native Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/flutter-app-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Flutter App Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/ionic-app-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Ionic App Development
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12 px-0 mb-5 overflow-hidden">
                    <h6 className="uppercase-text list-title">
                      <Link
                        to="/ui-ux-design-services.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        UI/UX Design
                      </Link>
                    </h6>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12 px-0 mb-5 overflow-hidden">
                    <h6 className="uppercase-text list-title">
                      <Link
                        to="/cloud-computing-solutions.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Cloud Computing
                      </Link>
                    </h6>
                  </div>
                  {/*<div className="col-12 col-md-6 col-xl-12 px-0 mb-5 overflow-hidden">
                    <h6 className="uppercase-text list-title">
                      <Link
                        to="/blockchain-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Blockchain Development
                      </Link>
                    </h6>
                  </div>*/}
                </div>
              </div>
              <div className="col-12 col-xl-4 px-0">
                <div className="row mx-0">
                  <div className=" col-12 col-md-6 col-xl-12 px-0 mb-5 overflow-hidden">
                    <h6 className="uppercase-text list-title">
                      <Link
                        to="/web-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Web Development
                      </Link>
                    </h6>
                    <ul className="ul-list">
                      <li>
                        <Link
                          to="/node-js-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Node.js Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/laravel-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Laravel Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/golang-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Golang Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/python-web-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Python Development
                        </Link>
                      </li>
                      {/*<li>
                        <Link
                          to="/ruby-on-rails-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Ruby on Rails Development
                        </Link>
                      </li>*/}
                      <li>
                        <Link
                          to="/php-web-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          PHP Web Development
                        </Link>
                      </li>
                      {/*<li>
                        <Link
                          to="cakephp-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          CakePHP Development
                        </Link>
                      </li>*/}
                    </ul>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12 px-0 mb-5 overflow-hidden">
                    <h6 className="uppercase-text list-title">
                      <p>Emerging Technologies</p>
                    </h6>
                    <ul className="ul-list">
                      <li>
                        <Link
                            to="/ibeacon-app-development.php"
                            onClick={context.handleMenu}
                            onMouseEnter={e =>
                                context.handleMouseEnter(e, "link")
                            }
                            onMouseLeave={context.handleMouseLeave}
                        >
                          iBeacon App Development
                        </Link>
                      </li>
                      <li>
                        <Link
                            to="/wearable-app-development.php"
                            onClick={context.handleMenu}
                            onMouseEnter={e =>
                                context.handleMouseEnter(e, "link")
                            }
                            onMouseLeave={context.handleMouseLeave}
                        >
                          Wearable App Development
                        </Link>
                      </li>
                      <li>
                        <Link
                            to="/internet-of-things-iot-app-development.php"
                            onClick={context.handleMenu}
                            onMouseEnter={e =>
                                context.handleMouseEnter(e, "link")
                            }
                            onMouseLeave={context.handleMouseLeave}
                        >
                          IoT App Development
                        </Link>
                      </li>
                    </ul>
                  </div>

                  {/*<div className="col-12 col-md-6 col-xl-12 px-0 mb-5 overflow-hidden">
                    <h6 className="uppercase-text list-title">
                      <p>CMS & Ecommerce</p>
                    </h6>
                    <ul className="ul-list">
                      <li>
                        <Link
                          to="/joomla-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Joomla Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/magento-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Magento Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/wordpress-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Wordpress Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/drupal-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Drupal Development
                        </Link>
                      </li>
                    </ul>
                  </div>*/}
                </div>
              </div>
              <div className="col-12 col-xl-4 px-0">
                <div className="row mx-0">
                  <div className="col-12 col-md-6 col-xl-12 px-0 mb-5 mb-md-0 mb-xl-5 overflow-hidden">
                    <h6 className="uppercase-text list-title">
                      <Link
                        to="/javascript-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        JavaScript Development
                      </Link>
                    </h6>
                    <ul className="ul-list">
                      <li>
                        <Link
                          to="/angularjs-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          AngularJS Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/reactjs-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          ReactJS Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/vuejs-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Vue.js Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/meteor-js-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          MeteorJS Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/backbone-js-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          BackboneJS Development
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/*<div className="col-12 col-md-6 col-xl-12 px-0 overflow-hidden">
                    <h6 className="uppercase-text list-title">
                      <p>Emerging Technologies</p>
                    </h6>
                    <ul className="ul-list">
                      <li>
                        <Link
                          to="/ibeacon-app-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          iBeacon App Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/wearable-app-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          Wearable App Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/internet-of-things-iot-app-development.php"
                          onClick={context.handleMenu}
                          onMouseEnter={e =>
                            context.handleMouseEnter(e, "link")
                          }
                          onMouseLeave={context.handleMouseLeave}
                        >
                          IoT App Development
                        </Link>
                      </li>
                    </ul>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

export default ServicesTab;
