import React from "react";
import "./NavHire.scss"
import {Link} from "react-router-dom";
import RightArrow from "../../assets/icons/right-arrow.svg";

const hireList = {
    hireMobile: {
        title: "Hire Mobile AppDevelopers",
        technologies: [
            {name: "Hire iPhone App Developers", url: "/hire-iphone-app-developers.php"},
            {name: "Hire Android App Developers", url: "/hire-android-app-developers.php"},
            {name: "Hire Swift Developers", url: "/hire-swift-developer.php"},
            {name: "Hire Flutter Developers", url: "/hire-flutter-app-developers.php"},
            {name: "Hire React Native Developers", url: "/hire-react-native-developer.php"},
        ]
    },
    hireFrontEnd: {
        title: "Hire Front-end Developers",
        technologies: [
            {name: "Hire ReactJS Developers", url: "/hire-reactjs-developers.php"},
            {name: "Hire Vue.js Developers", url: "/hire-vuejs-developer.php"},
            {name: "Hire AngularJS Developers", url: "/hire-angularjs-developers.php"},
            {name: "Hire Full Stack Developers", url: "/hire-full-stack-developer.php"}
        ]
    },
    hireGame: {
        title: "Game",
        technologies: [
            {name: "Hire Game Developers", url: "/hire-game-developers.php"}
        ]
    },
    hireBackEnd: {
        title: "Hire Back-end Developers",
        technologies: [
            {name: "Hire Golang Developers", url: "/hire-golang-developers.php"},
            {name: "Hire Python Developers", url: "/hire-python-developers.php"},
            {name: "Hire Node.js Developers", url: "/hire-node-js-developers.php"},
            {name: "Hire PHP Developers", url: "/hire-php-developers.php"}
        ]
    }
};

const NavHire = () => (
    <div className="dropdown-main">
        <div className="d-flex dropdown-hire py-30">
            <div className="dropdown-hire-left">
                <div className="label-box-main">
                    <h6 className="nav-item-label">{hireList.hireMobile.title}</h6>
                    <ul className="nav-item-ul-list">
                        {hireList.hireMobile.technologies.map((technology, i) => (
                            <li key={i}>
                                {technology.url ? (
                                    <Link to={technology.url}>{technology.name}</Link>
                                ) : (
                                    <p className="mb-0">{technology.name}</p>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="dropdown-hire-center">
                <div className="">
                    <div className="label-box-main">
                        <h6 className="nav-item-label">{hireList.hireFrontEnd.title}</h6>
                        <ul className="nav-item-ul-list">
                            {hireList.hireFrontEnd.technologies.map((technology, i) => (
                                <li key={i}>
                                    {technology.url ? (
                                        <Link to={technology.url}>{technology.name}</Link>
                                    ) : (
                                        <p className="mb-0">{technology.name}</p>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="hire-game-developers">
                        <h6 className="nav-item-label pt-4 pt-md-2 pt-xl-4 mt-2 ">{hireList.hireGame.title}</h6>
                        <ul className="nav-item-ul-list">
                            {hireList.hireGame.technologies.map((technology, i) => (
                                <li key={i}>
                                    {technology.url ? (
                                        <Link to={technology.url}>{technology.name}</Link>
                                    ) : (
                                        <p className="mb-0">{technology.name}</p>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="dropdown-hire-right">
                <div className="label-box-main">
                    <h6 className="nav-item-label">{hireList.hireBackEnd.title}</h6>
                    <ul className="nav-item-ul-list">
                        {hireList.hireBackEnd.technologies.map((technology, i) => (
                            <li key={i}>
                                {technology.url ? (
                                    <Link to={technology.url}>{technology.name}</Link>
                                ) : (
                                    <p className="mb-0">{technology.name}</p>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
        <div>
            <div className="d-flex dropdown-hire-bottom">
                <div className="d-flex flex-wrap justify-content-between dropdown-hire-left-bottom bg-hire-bottom-left">
                    <Link to='/hire-ui-ux-designer.php' className="d-flex align-items-center">
                        <span className="dropdown-left-bottom-title">Hire UI/UX Designers</span>
                    </Link>
                </div>
                <div className="d-flex flex-wrap dropdown-hire-center-bottom bg-hire-bottom-center">
                    <Link to='/hire-software-qa-engineers.php' className="d-flex align-items-center">
                        <span className="nav-item-title">Hire QA Engineers</span>
                    </Link>
                </div>
                <div className="d-flex dropdown-hire-right-bottom bg-hire-bottom-right">
                    <Link to='hire-dedicated-developers.php' className="w-100 d-flex align-items-center justify-content-between">
                        <span className="dropdown-visit-more-text dropdown-visit-more-hire mr-5">
                          Explore more on our Hiring Services
                        </span>
                        <img loading='true' src={RightArrow} alt="right-arrow"/>
                    </Link>
                </div>
            </div>
        </div>

    </div>
);

export default NavHire;
