import React from "react";
import { Link } from "react-router-dom";
import { Consumer } from "../../Context";

class CompanyTab extends React.Component {
  state = {
    height: 0
  };
  componentDidMount() {
    const height = document.getElementById("companyTab").getBoundingClientRect()
      .height;
    this.setState({ height });
  }
  render() {
    return (
      <Consumer>
        {context => (
          <div className="tab-detail" style={{ height: this.state.height }}>
            <div className="row mx-0 mt-5" id="companyTab">
              <div className="col-12 col-md-6 col-xl-4 px-0 mb-5 mb-xl-0">
                <div className="overflow-hidden">
                  <h6 className="uppercase-text list-title">
                    <p>About Us</p>
                  </h6>
                  <ul className="ul-list">
                    <li>
                      <Link
                        to="/about-us.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Company Overview
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/testimonial.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Testimonials
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/careers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Careers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact-us.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 px-0 mb-5 mb-xl-0">
                <div className="overflow-hidden">
                  <h6 className="uppercase-text list-title">
                    <p>Others</p>
                  </h6>
                  <ul className="ul-list">
                    <li>
                      <Link
                        to="/life-at-mi.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Life @ MI
                      </Link>
                    </li>
                    {/*<li>
                      <Link to="">Streaming @ MI</Link>
                    </li>*/}
                    <li>
                      <Link
                        to="/faq.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        FAQ
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 px-0">
                <div className="overflow-hidden">
                  <h6 className="uppercase-text list-title">
                    <p>Insights</p>
                  </h6>
                  <ul className="ul-list">
                    <li>
                      <a
                        href="https://www.oceansharkdigisol.com/blog"
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                        target="_blank" rel="noopener noreferrer"
                      >
                        Blog
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

export default CompanyTab;
