import React from "react";
import {Link} from "react-router-dom";
import MenuMobileIcon from "../../assets/icons/menu-mobile-icon.svg";
import MenuWebIcon from "../../assets/icons/menu-web-icon.svg";
import MenuGraphicIcon from "../../assets/icons/menu-graphic-icon.svg";
import MenuQaIcon from '../../assets/icons/menu-qa-icon.svg'
import MenuCloudIcon from "../../assets/icons/menu-cloud-icon.svg";
import MenuIotIcon from "../../assets/icons/menu-iot-icon.svg";
import RightArrow from "../../assets/icons/right-arrow.svg";
import MenuWatchIcon from "../../assets/icons/menu-watch-icon.svg";
import MenuIbeaconIcon from "../../assets/icons/menu-ibeacon-icon.svg";
import MenuQuote from "../../assets/icons/menu-quote.svg";
import Game from "../../assets/icons/game.svg";

const servicesList = {
    nativeApp: {
        title: "Native  App ",
        technologies: [
            {name: "iOS App Development", url: "/iphone-application-development.php"},
            {name: "Android App Development", url: "/android-application-development.php"},
            {name: "Swift App Development", url: "/swift-app-development.php"}
        ]
    },
    crossPlatform: {
        title: "Cross Platform  App ",
        technologies: [
            {
                name: "React Native App Development",
                url: "/react-native-app-development.php"
            },
            {name: "Flutter App Development", url: "/flutter-app-development.php"},
        ]
    },
    frontEnd: {
        title: "Front-end",
        technologies: [
            {name: "ReactJS Development", url: "/reactjs-development.php"},
            {name: "Vue.js Development", url: "/vuejs-development.php"},
            {name: "AngularJS Development", url: "/angularjs-development.php"},
            {name: "JavaScript Development", url: "/javascript-development.php"},
            {name: "BackboneJS Development", url: "/backbone-js-development.php"}
        ]
    },
    backEnd: {
        title: "Back-end",
        technologies: [
            {name: "Node.js Development", url: "/node-js-development.php"},
            {name: "Laravel Development", url: "/laravel-development.php"},
            {name: "Golang Development", url: "/golang-development.php"},
            {name: "Python Development", url: "/python-web-development.php"},
            {name: "MeteorJS Development", url: "/meteor-js-development.php" },
            {name: "PHP Web Development", url: "/php-web-development.php"}
        ]
    },
};
const NavServices = () => (
    <div className="dropdown-main">
        <div className="d-flex dropdown-services py-30">
            <div className="dropdown-services-left">
                <img loading='lazy' className="mb-3" src={MenuMobileIcon} alt="mobile-icon"/>
                <Link to='/mobile-app-development.php' className="nav-item-title mb-4 pb-2">Mobile App
                    Development</Link>
                <div className='dropdown-services-mobile-section'>
                    <div className="mb-4 dropdown-services-mobile-section-left">
                        <div className="label-box-main">
                            <h6 className="nav-item-label">{servicesList.nativeApp.title}</h6>
                            <ul className="nav-item-ul-list">
                                {servicesList.nativeApp.technologies.map((technology, i) => (
                                    <li key={i}>
                                        {technology.url ? (
                                            <Link to={technology.url}>{technology.name}</Link>
                                        ) : (
                                            <p className="mb-0">{technology.name}</p>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="label-box-main">
                        <h6 className="nav-item-label">{servicesList.crossPlatform.title}</h6>
                        <ul className="nav-item-ul-list">
                            {servicesList.crossPlatform.technologies.map((technology, i) => (
                                <li key={i}>
                                    {technology.url ? (
                                        <Link to={technology.url}>{technology.name}</Link>
                                    ) : (
                                        <p className="mb-0">{technology.name}</p>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="dropdown-services-center">
                <img loading='lazy' className="mb-3" src={MenuWebIcon} alt="web-icon"/>
                <Link to='/web-development.php' className="nav-item-title mb-4 pb-2">Web App Development</Link>
                <div className="d-flex dropdown-services-center-content">
                    <div className="dropdown-services-center-content-left">
                        <div className="label-box-main">
                            <h6 className="nav-item-label">{servicesList.frontEnd.title}</h6>
                            <ul className="nav-item-ul-list">
                                {servicesList.frontEnd.technologies.map((technology, i) => (
                                    <li key={i}>
                                        {technology.url ? (
                                            <Link to={technology.url}>{technology.name}</Link>
                                        ) : (
                                            <p className="mb-0">{technology.name}</p>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="dropdown-services-center-content-right">
                        <div className="label-box-main">
                            <h6 className="nav-item-label">{servicesList.backEnd.title}</h6>
                            <ul className="nav-item-ul-list">
                                {servicesList.backEnd.technologies.map((technology, i) => (
                                    <li key={i}>
                                        {technology.url ? (
                                            <Link to={technology.url}>{technology.name}</Link>
                                        ) : (
                                            <p className="mb-0">{technology.name}</p>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dropdown-services-right">
                <div className="d-flex align-items-center mb-4">
          <span className="menu-svg-block mr-3">
            <img
                src={MenuQaIcon}
                className="img-fluid"
                alt="Qa-icon"
            />
          </span>
                    <Link to='/qa-software-testing-services.php' className="nav-item-title mb-0">QA Services</Link>
                </div>
                <div className="d-flex align-items-center mb-4">
          <span className="menu-svg-block mr-3">
            <img
                src={MenuCloudIcon}
                className="img-fluid"
                alt="Cloud-icon"
            />
          </span>
                    <Link to='/cloud-computing-solutions.php' className="nav-item-title mb-0">Cloud Computing</Link>
                </div>
                <div className="d-flex align-items-center mb-4">
          <span className="menu-svg-block mr-3">
            <img
                src={MenuIbeaconIcon}
                className="img-fluid"
                alt="ibeacon-icon"
            />
          </span>
                    <Link to='/ibeacon-app-development.php' className="nav-item-title mb-0">iBeacon Apps</Link>
                </div>
                <div className="d-flex align-items-center mb-4">
          <span className="menu-svg-block mr-3">
            <img
                src={MenuWatchIcon}
                className="img-fluid"
                alt="watch-icon"
            />
          </span>
                    <Link to='/wearable-app-development.php' className="nav-item-title mb-0">Wearable Apps</Link>
                </div>
                <div className="d-flex align-items-center mb-4">
          <span className="menu-svg-block mr-3">
            <img src={MenuIotIcon} className="img-fluid" alt="Iot-icon"/>
          </span>
                    <Link to='/internet-of-things-iot-app-development.php' className="nav-item-title mb-0">IoT
                        Apps</Link>
                </div>
                <div className="d-flex align-items-center mb-4">
          <span className="menu-svg-block mr-3">
            <img
                src={Game}
                className="img-fluid"
                alt="watch-icon"
            />
          </span>
                    <Link to='/game-development-services.php' className="nav-item-title mb-0">Game Development</Link>
                </div>
            </div>
        </div>
        <div className="d-flex dropdown-services-bottom">
            <div className="d-flex dropdown-services-left-bottom bg-services-bottom-left">
                <div
                    className="w-100 dropdown-services-left-bottom-graphics d-flex align-items-center justify-content-between">
                    <Link to='/ui-ux-design-services.php' className='d-flex align-items-center'>
                        <img loading='lazy' className='mr-3' src={MenuGraphicIcon} alt="graphic-icon"/>
                        <span className="dropdown-left-bottom-title">UI/UX Design & Graphic Design</span>
                    </Link>
                    <div className='d-flex align-items-center nav-service-special-text-div'>
                        <img loading='lazy' className='mr-3' src={MenuQuote} alt="Quote-icon"/>
                        <span className="nav-service-special-text">Good design is key to succesful product.</span>
                    </div>
                </div>
            </div>
            <div className="d-flex dropdown-services-right-bottom bg-services-bottom-right">
                <Link to='/services.php' className='w-100 d-flex align-items-center justify-content-between'>
                    <span className="dropdown-visit-more-text mr-3">Explore All Services</span>
                    <img src={RightArrow} alt="right-arrow"/>
                </Link>
            </div>
        </div>
    </div>
);
export default NavServices;
