import React from "react";
import "./Menu.scss";
import GridLines from "../GridLines/GridLines";
import PortfolioVideo from "../PortfolioSection/PortfolioVideo";
import ServicesTab from "./ServicesTab";
import SolutionsTab from "./SolutionsTab";
import ExtensionTab from "./ExtensionTab";
import CompanyTab from "./CompanyTab";
import { Consumer, Context } from "../../Context";
import { Link } from "react-router-dom";
import Header from "../Header/Header";

class Menu extends React.Component {
  state = {
    tabTitles: [
      {
        title: "Services",
        target: "Services",
        to: "/services.php",
        tab: <ServicesTab />
      },
      {
        title: "Solutions",
        target: "Solutions",
        to: "",
        tab: <SolutionsTab />
      },
      {
        title: "Extension",
        target: "Extension",
        to: "/hire-dedicated-developers.php",
        tab: <ExtensionTab />
      },
      {
        title: "Work",
        target: "Work",
        to: "/all-portfolios.php",
        tab: <PortfolioVideo />
      },
      {
        title: "Company",
        target: "Company",
        to: "/about-us.php",
        tab: <CompanyTab />
      }
    ],
    openTab: "Services",
    init: true
  };

  handleOpenTab = tabName => {
    this.state.init && this.setState({ init: false });
    this.setState({ openTab: tabName ? tabName : this.state.openTab });
  };

  componentDidMount() {
    this.setState({
      openTab: this.context.windowWidth < 1200 ? "" : "Services"
    });
  }

  render() {
    return (
      <Consumer>
        {context => (
          <div
            className={`${
              this.state.init
                ? "wrapper menu-wrapper dark-mode initial"
                : "wrapper menu-wrapper dark-mode"
            }`}
          >
            <div className="content-wrapper">
              <Header logoTheme="white" />
              <div className="container-fluid">
                <div className="row flex-column menu-content">
                  <div className="col overflow-auto">
                    <div className="row">
                      <div className="col-10 col-sm-8 col-xl-2 offset-1 offset-sm-2 px-0">
                        <ul className="tab-titles">
                          {this.state.tabTitles.map((item, i) => (
                            <li key={i} className="mt-5">
                              {item.to === "" ? (
                                <span
                                  className={`${
                                    this.state.openTab === item.target
                                      ? "d-inline-block outlined-text uppercase-text active"
                                      : "d-inline-block outlined-text uppercase-text"
                                  }`}
                                  onMouseOver={() =>
                                    this.handleOpenTab(item.target)
                                  }
                                >
                                  {item.title}
                                </span>
                              ) : (
                                <Link
                                  to={item.to}
                                  className={`${
                                    this.state.openTab === item.target
                                      ? "d-inline-block outlined-text uppercase-text active"
                                      : "d-inline-block outlined-text uppercase-text"
                                  }`}
                                  onMouseOver={() =>
                                    this.handleOpenTab(item.target)
                                  }
                                  onMouseEnter={e =>
                                    context.handleMouseEnter(e, "link")
                                  }
                                  onMouseLeave={context.handleMouseLeave}
                                  onClick={context.handleMenu}
                                >
                                  {item.title}
                                </Link>
                              )}
                              <button
                                className="d-xl-none expand"
                                onClick={() => this.handleOpenTab(item.target)}
                              >
                                +
                              </button>
                              {context.windowWidth < 1200 &&
                                this.state.openTab === item.target &&
                                item.tab}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-6 px-0">
                        {context.windowWidth >= 1200 &&
                          this.state.openTab === "Services" && <ServicesTab />}
                        {context.windowWidth >= 1200 &&
                          this.state.openTab === "Solutions" && (
                            <SolutionsTab />
                          )}
                        {context.windowWidth >= 1200 &&
                          this.state.openTab === "Extension" && (
                            <ExtensionTab />
                          )}
                        {context.windowWidth >= 1200 &&
                          this.state.openTab === "Work" && (
                            <div className="row mx-0 tab-detail py-5">
                              <div className="col-12 px-0">
                                <PortfolioVideo />
                              </div>
                            </div>
                          )}
                        {context.windowWidth >= 1200 &&
                          this.state.openTab === "Company" && <CompanyTab />}
                      </div>
                    </div>
                  </div>
                  <div className="col secondary-menu">
                    <div className="row align-items-center h-100">
                      <div className="col-10 col-sm-8 col-xl-6 offset-1 offset-sm-2 offset-xl-4 px-0 overflow-hidden">
                        <ul className="d-flex regular-text">
                          <li className="pr-3">
                            <Link
                                href="https://www.oceansharkdigisol.com/blog/"
                                target="_blank" rel="noopener noreferrer"
                                onMouseEnter={e =>
                                    context.handleMouseEnter(e, "link")
                                }
                                onMouseLeave={context.handleMouseLeave}
                            >
                              Blog
                            </Link>
                          </li>
                          {/*<li>|</li>
                          <li className="px-3">
                            <Link
                              to="/technologies.php"
                              onClick={context.handleMenu}
                              onMouseEnter={e =>
                                context.handleMouseEnter(e, "link")
                              }
                              onMouseLeave={context.handleMouseLeave}
                            >
                              Technologies
                            </Link>
                          </li>*/}
                          <li>|</li>
                          <li className="px-3">
                            <Link
                              to="/privacy-policy.php"
                              onClick={context.handleMenu}
                              onMouseEnter={e =>
                                context.handleMouseEnter(e, "link")
                              }
                              onMouseLeave={context.handleMouseLeave}
                            >
                              Privacy policy
                            </Link>
                          </li>
                          <li>|</li>
                          <li className="pl-3">
                            <Link
                              to="/sitemap.php"
                              onClick={context.handleMenu}
                              onMouseEnter={e =>
                                context.handleMouseEnter(e, "link")
                              }
                              onMouseLeave={context.handleMouseLeave}
                            >
                              Sitemap
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <GridLines />
              <div className="container-fluid animated-bars">
                <div className="row mx-0">
                  <div className="col-2 bar" />
                  <div className="col-2 bar" />
                  <div className="col-2 bar" />
                  <div className="col-2 bar" />
                  <div className="col-2 bar" />
                  <div className="col-2 bar" />
                </div>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}
Menu.contextType = Context;
export default Menu;
