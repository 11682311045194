import React, {Component} from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import styled, {keyframes} from 'styled-components';
import memoize from 'memoize-one';

const defaultColor = '#fff';
const defaultRowHeight = 80;
const defaultBackground = '#111111';
const defaultBreakpoint = 1080;
const defaultContentBackground = '#1A1A1A';
const defaultContentColor = '#fff';
const defaultContentWidth = 320;
const defaultContentHeight = 200;
const primaryColor = '#ED184F';
const perspective = 850;

let fadeOutSeconds = 0.45;
const fadeInSeconds = 0.45;
const moveSeconds = 0.3;
const fadeOutContentSeconds = 0;
const fadeInContentSeconds = 0;

const GridContainer = styled.div`
  @media (max-width: ${({breakpoint}) => (breakpoint - 1)}px) {
    position: absolute;
    visibility: hidden;
  }

  @media (min-width: ${({breakpoint}) => breakpoint}px) {
    display: grid;
    padding-right: 2rem;
    justify-content: right;
    justify-items: stretch;
    grid-template-columns: auto;
    grid-gap: 0 4rem;
    grid-template-rows: ${({rowHeight}) => rowHeight}px;
    position: relative;
  }
`;
const GridItemLink = styled(Link)`
  grid-column: ${({index}) => index + 1} / span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  color: ${({active, activate}) => activate && active ? defaultContentColor : ''};

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: ${({active, activate}) => activate && active ? '100%' : 0};
    height: 2px;
    background: ${primaryColor};
    transition: width .3s;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
    color: ${({active, activate}) => activate && active ? defaultContentColor : ''};
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
`;
const GridItemLinkA = styled.a`
  grid-column: ${({index}) => index + 1} / span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  color: ${({active, activate}) => activate && active ? defaultContentColor : ''};

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: ${({active, activate}) => activate && active ? '100%' : 0};
    height: 2px;
    background: ${primaryColor};
    transition: width .3s;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
    color: ${({active, activate}) => activate && active ? defaultContentColor : ''};
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
`;
const GridItem = styled(Link)`
  grid-column: ${({index}) => index + 1} / span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  color: ${({active, activate}) => activate && active ? defaultContentColor : ''};

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: ${({active, activate}) => activate && active ? '100%' : 0};
    height: 2px;
    background: ${primaryColor};
    transition: width .3s;
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: ${({active, activate}) => activate && active ? defaultContentColor : ''};

    &::after {
      width: 100%;
    }
  }
`;
const ContentRow = styled.div`
  grid-column: 1 / span ${({columns}) => columns};
  grid-row: 2 / span 1;
  height: 0;
`;
const Move = (fromData, toData) => keyframes`
  from {
    width: ${fromData.width}px;
    height: ${fromData.height}px;
  }

  to {
    width: ${toData.width}px;
    height: ${toData.height}px;
  }
`;
const FadeIn = keyframes`
  from {
    opacity: 0;
    transform: perspective(${perspective}px) rotateX(-60deg);
    transform-origin: top center;
  }

  to {
    opacity: 1;
    transform: perspective(${perspective}px) rotateX(0deg);
    transform-origin: top center;
  }
`;
const FadeOut = keyframes`
  from {
    opacity: 1;
    transform: perspective(${perspective}px) rotateX(0deg);
    transform-origin: top center;
  }

  to {
    opacity: 0;
    transform: perspective(${perspective}px) rotateX(-60deg);
    transform-origin: top center;
    visibility: hidden;
  }
`;
const MovingDiv = styled.div`
  opacity: 1;
  overflow: hidden;
  position: absolute;
  background: ${defaultContentBackground};
  top: ${({top}) => top}px;
  right: -12.5rem;
  width: ${({fromData}) => fromData ? fromData.width : 0}px;
  height: ${({fromData}) => fromData ? fromData.height : 0}px;
  display: ${({display}) => display};
  border-radius: 10px;
  box-shadow: 0 5px 15px 1px rgba(138, 126, 138, 0.1);
  animation: ${({fadeOut, display, fromData, toData}) => {
    if (fadeOut) return FadeOut;
    if (display === 'block') {
      if (fromData.left === toData.left) return FadeIn;
      // if(fromData.width === 0) return ;
      if (fromData) return Move(fromData, toData);
    }
    return ''; // display: none; don't animate
  }} // fade out and in slower than moving sideways
  ${({fadeOut, display, fromData, toData}) => {
    if (fadeOut) return `${fadeOutSeconds}s`;
    if (display === 'block') {
      if (fromData.left === toData.left) return `${fadeInSeconds}s`; // fade in
      if (fromData) return `${moveSeconds}s`; // move
    }
    return '0s'; // display: none; don't animate
  }} forwards ease;
`;

const FadeInContent = keyframes`
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const FadeOutContent = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-50px);
    visibility: hidden;
  }
`;
const ContentGroupContainer = styled.div`
  position: absolute;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  opacity: ${({show}) => show ? 1 : 0};
  z-index: ${({show}) => show ? 1 : 0};
  pointer-events: ${({show}) => show ? 'auto' : 'none'};
  animation: ${({show, fadeOut}) => {
    if (show) return FadeInContent;
    if (fadeOut) return FadeOutContent;
    return '';
  }} ${({show}) => show ? `${fadeInContentSeconds}` : `${fadeOutContentSeconds}`}s forwards;
`;
export const ContentGroup = ({title, width, height, background}) => {
    return (
        <>
            {title}
            {width}x{height}
            {background}
        </>
    );
};

class MiNav extends Component {
    state = {display: 'none', fadeOut: false, fromData: null, toData: null, activate: false, leftOffset: 0, rightOffset: 0};

    componentDidUpdate(prevProps) {
        const locationChanged =
            this.props.location !== prevProps.location;
        if(locationChanged){
            fadeOutSeconds = 0
        }
    }

    static defaultProps = {
        rowHeight: defaultRowHeight,
        background: defaultBackground,
        contentBackground: defaultContentBackground,
        contentColor: defaultContentColor,
        breakpoint: defaultBreakpoint,
        color: defaultColor,
        debug: false,
        navClass: ""
    };

    memoizeMenuData = memoize((columnWidth, children) => React.Children.map(children, (child, i) => {
        // if width and height are not specified, that means we don't want to render the content group i.e. we only
        // want to render root item
        const {width, height} = child.props;
        let sanitisedWidth, sanitisedHeight;

        if (!width && !height) {
            sanitisedWidth = 0;
            sanitisedHeight = 0;
        } else {
            // if width or height is not specified, add defaults
            sanitisedWidth = width || defaultContentWidth;
            sanitisedHeight = height || defaultContentHeight;
        }

        return {
            ...child.props, // order is important here! spread child.props after height, followed by width.
            height: sanitisedHeight,
            width: sanitisedWidth,
            index: i,
            left: window.innerWidth - sanitisedWidth,
            right:0
        };
    }));
    memoizeGridItems = memoize((children, color, toData, activate) => React.Children.map(children, (child, i) => {
            const {title, rootUrl, navItemLinkClass, width} = child.props;
            if(rootUrl === '/blog/') {
                return (
                    <GridItemLinkA
                        href={rootUrl}
                        activate={activate ? 1 : 0}
                        active={toData && toData.index === i ? 1 : 0}
                        key={`menu-title-${i}`}
                        index={i}
                        onMouseEnter={(e) => {
                            this.onMouseEnter(e.target, i);
                            this.gridItemLinkClick(e.target, i)
                        }}
                        color={color}
                        className={navItemLinkClass}
                    >
                        {title}
                    </GridItemLinkA>
                );
            }
            if (!width) {
                return (
                    <GridItemLink
                        to={rootUrl}
                        activate={activate ? 1 : 0}
                        active={toData && toData.index === i ? 1 : 0}
                        key={`menu-title-${i}`}
                        index={i}
                        onMouseEnter={(e) => { this.onMouseEnter(e.target, i); this.gridItemLinkClick(e.target, i)}}
                        color={color}
                        className={navItemLinkClass}
                    >
                        {title}
                    </GridItemLink>
                );
            }

            return (
                <GridItem
                    key={`menu-title-${i}`}
                    to={rootUrl}
                    activate={activate ? 1 : 0}
                    active={toData && toData.index === i ? 1 : 0}
                    index={i}
                    onMouseEnter={(e) => this.onMouseEnter(e.target, i)}
                    color={color}
                >
                    {title}
                </GridItem>
            );
        }
    ));
    memoizeContent = memoize((children, fromData, toData) => React.Children.map(children, (child, i) => (
        <ContentGroupContainer
            key={`content-group-${i}`}
            show={toData && toData.index === i}
            fadeOut={fromData && fromData.index === i}
        >
            {child.props.children}
        </ContentGroupContainer>
    )));
    memoizeColumns = memoize(children => React.Children.count(children));

    close = () => {
        if (this.props.debug) return;
        this.setState((prevState) => ({fadeOut: true, fromData: prevState.toData, activate : false}));
    };

        onMouseEnter = (target, menuDataIndex) => {
        this.setState((prevState) => {
            let fadeOut = false;
            const activate = true;
            const display = 'block';
            const toDataOriginal = this.memoizeMenuData(this.props.columnWidth, this.props.children)[menuDataIndex];
            const toData = {...toDataOriginal};
            let leftOffset = 0;
            let rightOffset = 0;



            if (target) { // off screen detection
                // target is rootGridItem

                let fromData;
                if (prevState.fadeOut || !prevState.toData) {
                    // on cold start, pop up right from the current item
                    fromData = toData;
                } else {
                    // on warm start, start animation from the previous item
                    fromData = prevState.toData;
                }
                return {
                    display,
                    activate,
                    fadeOut,
                    fromData,
                    toData,
                    leftOffset,
                    rightOffset,
                };
            }
        });
    };
    gridItemLinkClick = (target, menuDataIndex) => {
        const toDataOriginal = this.memoizeMenuData(this.props.columnWidth, this.props.children)[menuDataIndex];
        const toData = {...toDataOriginal};
        this.setState({
            fadeOut : true,
            toData: toData
        })
    };

    onMouseLeave = () => this.close();
    onClickMovingDiv = () => this.close();

    render() {
        const {
            columnWidth, rowHeight, background, contentBackground, contentColor,
            children, fontSize, fontFamily, color, breakpoint, navClass
        } = this.props;
        const {fromData, toData, display, fadeOut, activate} = this.state;
        const columns = this.memoizeColumns(children);
        const rootGridItems = this.memoizeGridItems(children, color, toData, activate);
        const content = this.memoizeContent(children, fromData, toData);
        const contentBackgroundSanitised = (toData && toData.background) || contentBackground;

        return (
            <nav className={navClass}>
                <GridContainer
                    background={background}
                    columnWidth={columnWidth}
                    rowHeight={rowHeight}
                    fontSize={fontSize}
                    fontFamily={fontFamily}
                    color={color}
                    breakpoint={breakpoint}

                    onMouseLeave={this.onMouseLeave}
                    columns={columns}
                >
                    {rootGridItems}
                    <ContentRow columns={columns}>
                        <MovingDiv
                            display={display}
                            fadeOut={fadeOut}
                            fromData={fromData}
                            toData={toData}
                            color={contentColor}
                            top={rowHeight}
                            onClick={this.onClickMovingDiv}
                            background={contentBackgroundSanitised}
                        >
                            {content}
                        </MovingDiv>
                    </ContentRow>
                </GridContainer>
            </nav>
        );
    }
}

export default withRouter(MiNav)
