import React from "react";
import { Link } from "react-router-dom";
import RightArrow from "../../assets/icons/right-arrow.svg";

const solutionsList = {
  enterprise: {
    title: "Enterprise Solutions",
    solutions: [
      { name: "Cloud Computing Solutions", url: "/cloud-computing-solutions.php" },
      { name: "Enterprise Mobility Solutions", url: "/enterprise-app-development.php" },
      { name: "Product Engineering", url: "/product-engineering-services.php" },
      { name: "Startup Services", url: "/app-development-for-startups.php" },
      { name: "DevOps Services", url: "/devops-solutions.php" },
      { name: "Digital Transformation", url: "/digital-transformation-services.php" }
    ]
  },
  trending: {
    title: "Trending Solutions",
    solutions: [
      { name: "Fintech Solutions", url: "/fintech-solutions.php"},
      { name: "Ecommerce Solutions", url: "/ecommerce-solutions.php"},
      { name: "Healthcare Solutions", url: "/healthcare-solutions.php"},
      { name: "Fitness Solutions", url: "fitness-solutions.php"},
      { name: "Food Delivery Solutions", url: "/food-delivery-solutions.php"},
      { name: "On-demand Solutions", url: "/on-demand-app-development.php"}
    ]
  },
    industries: {
        title: "Industries",
        solutions: [
            { name: "Real Estate", url: "real-estate-app-development.php"},
            { name: "Travel", url: "/travel-app-development.php"},
            { name: "Sports", url: "/sports-app-development.php"},
            { name: "Social Networking", url: "/social-media-app-development.php"},
            { name: "Business", url: "/business-application-development.php"},
            { name: "eLearning & Education", url: "/educational-app-development-solutions.php"},
            { name: "Media & Entertainment", url: "/entertainment-app-development.php"},
        ]
    },
};

const NavSolutions = () => (
  <div className="dropdown-main">
    <div className="row dropdown-solutions mx-0 py-30">
      <div className="dropdown-solutions-left">
        <div className="label-box-main">
          <h6 className="nav-item-label">{solutionsList.enterprise.title}</h6>
          <ul className="nav-item-ul-list">
            {solutionsList.enterprise.solutions.map((solution, i) => (
              <li key={i}>
                {solution.url ? (
                  <Link to={solution.url}>{solution.name}</Link>
                ) : (
                  <p className="mb-0">{solution.name}</p>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="dropdown-solutions-center">
        <div className="label-box-main">
            <h6 className="nav-item-label">{solutionsList.trending.title}</h6>
            <ul className="nav-item-ul-list">
              {solutionsList.trending.solutions.map((solution, i) => (
                <li key={i}>
                  {solution.url ? (
                    <Link to={solution.url}>{solution.name}</Link>
                  ) : (
                    <p className="mb-0">{solution.name}</p>
                  )}
                </li>
              ))}
            </ul>
          </div>
      </div>

      <div className="dropdown-solutions-right">
          <div className="label-box-main">
              <h6 className="nav-item-label">{solutionsList.industries.title}</h6>
              <ul className="nav-item-ul-list">
                  {solutionsList.industries.solutions.map((solution, i) => (
                      <li key={i}>
                          {solution.url ? (
                              <Link to={solution.url}>{solution.name}</Link>
                          ) : (
                              <p className="mb-0">{solution.name}</p>
                          )}
                      </li>
                  ))}
              </ul>
          </div>
      </div>
    </div>

      <div className="d-flex dropdown-services-bottom">
          <div className="d-flex dropdown-solutions-left-bottom bg-services-bottom-left">
              <div
                  className="w-100 dropdown-solutions-left-bottom-text d-flex align-items-center justify-content-between">
                  <Link to='/contact-us.php' className='w-100 justify-content-between d-flex flex-wrap align-items-center'>
                      <span className="dropdown-left-bottom-title whitespace-nowrap">Have a Project?
                        <span className='solution-bottom-left-text whitespace-nowrap'> Get Free Consultation</span>
                      </span>
                  <div className='d-flex align-items-center'>
                      <span className="dropdown-solution-contact-us mr-3">Contact Us </span>
                      <img loading='lazy' src={RightArrow} alt="right-arrow"/>
                  </div>
                  </Link>
              </div>
          </div>

          <div className="d-flex dropdown-solutions-right-bottom bg-services-bottom-right">
              <Link to='/solutions.php' className='w-100 d-flex align-items-center justify-content-between'>
                  <span className="dropdown-visit-more-text mr-3">Explore All Solutions </span>
                  <img src={RightArrow} alt="right-arrow"/>
              </Link>
          </div>
      </div>
  </div>
);

export default NavSolutions;
