// import foodDeliverySuccessStory from "./../SuccessStoriesSlider/imgs/bhoj.webp";
// import pigiSuccessStory from "./../SuccessStoriesSlider/imgs/pigi.webp";
// import abWorkSuccessStory from "./../SuccessStoriesSlider/imgs/6pp-ab-workout.webp";
// import mybus from "./imgs/mybus-thumb-thumb.png";
// import sendPostCards from "./imgs/send-postcards-app-thumb.jpg";
//import BayaAppImg from "./imgs/the-baya-app-thumb.png";

import moneymakerroundImg from "./../../pages/PortfolioListing/images/moneymaker-r.webp";
import MoneyMakerthumbnail from "./../../pages/PortfolioListing/images/moneymaker-thumbnail.webp";
import purebeautyroundImg from "./../../pages/PortfolioListing/images/pure-r.webp";
import purebeautythumbnail from "./../../pages/PortfolioListing/images/pure-thumbnail.webp";
import plantShopCircleThumb_png from "./../../pages/PortfolioListing/images/plant-shop-circle.png";
import plantShopCircleThumb from "./../../pages/PortfolioListing/images/plant-shop-circle.png";
import travelAgencyCircleThumb_png from "./../../pages/PortfolioListing/images/travel-agency-circle.png";
import travelAgencyCircleThumb from "./../../pages/PortfolioListing/images/travel-agency-circle.webp";
import dudepinsCircleThumb_png from "./../../pages/PortfolioListing/images/dudepins-circle.png";
import dudepinsCircleThumb from "./../../pages/PortfolioListing/images/dudepins-circle.webp";
import parentalControlAppCircleThumb_png from "./../../pages/PortfolioListing/images/parental-control-app-circle.png";
import parentalControlAppCircleThumb from "./../../pages/PortfolioListing/images/parental-control-app-circle.webp";
import smartHomeNuemorphismCircleThumb_png from "./../../pages/PortfolioListing/images/smart-home-nuemorphism-circle.png";
import smartHomeNuemorphismCircleThumb from "./../../pages/PortfolioListing/images/smart-home-nuemorphism-circle.webp";
import medsureCircleThumb_png from "./../../pages/PortfolioListing/images/medsure-circle.png";
import medsureCircleThumb from "./../../pages/PortfolioListing/images/medsure-circle.webp";
import salonAppCircleThumb_png from "./../../pages/PortfolioListing/images/salon-app-circle.png";
import salonAppCircleThumb from "./../../pages/PortfolioListing/images/salon-app-circle.webp";
import timeManagementCircleThumb_png from "./../../pages/PortfolioListing/images/time-management-circle.png";
import timeManagementCircleThumb from "./../../pages/PortfolioListing/images/time-management-circle.webp";
import stockMarketCircleThumb_png from "./../../pages/PortfolioListing/images/stock-market-circle.png";
import stockMarketCircleThumb from "./../../pages/PortfolioListing/images/stock-market-circle.webp";
import taskManagementCircleThumb_png from "./../../pages/PortfolioListing/images/task-management-circle.png";
import taskManagementCircleThumb from "./../../pages/PortfolioListing/images/task-management-circle.webp";
import headphoneControllerAppCircleThumb_png from "./../../pages/PortfolioListing/images/headphone-controller-app-circle.png";
import headphoneControllerAppCircleThumb from "./../../pages/PortfolioListing/images/headphone-controller-app-circle.webp";
import ticketBookingAppCircleThumb_png from "./../../pages/PortfolioListing/images/ticket-booking-app-circle.webp";
import ticketBookingAppCircleThumb from "./../../pages/PortfolioListing/images/ticket-booking-app-circle.webp";

// import findAtoZImg from "./imgs/find-a-to-z-thumb.jpg";
// import travelSocialNetworkImg from "./imgs/travel-social-network-app-thumb.jpg";
// import KermisWeertImg from "./imgs/kermisweert-thumb.png";
// import helponymousImg from "./imgs/helponymo_thumb.jpg";
// import DentalCareImg from "./imgs/dentalcare_thumb.jpg";
// import edSmartImg from "./imgs/edsmart---education-app-thumb.png";
// import engageMusic from "./imgs/engage-music-app-thumb.png";
// import friendSongsApp from "./imgs/friendsongs-app-thumb.png";
//import pengeapod from "./imgs/pengeapod-app-thumb.jpg";
// import CopomapImg from "./imgs/coupons-&-deals-app-thumb.jpg";
// import realtimeRunning from "./imgs/realtime-running-app-thumb.png";
// import smashers from "./imgs/smashers-collectors-app-thumb.png";
// import threebuzz from "./imgs/threebuzz-thumb.png";
// import voxpop from "./imgs/voxpop---survey-and-opinion-app-thumb.jpg";
// import weact from "./imgs/weact-thumb.jpg";
// import yuuud from "./imgs/yuuud---social-network-app-thumb.jpg";
// import videoSharingImg from "./web/video-sharing-app-thumb.png";
//import enneaAppSuccessStory from "./../SuccessStoriesSlider/imgs/enneaapp.webp";
// import ZoominSuccessStory from "./../SuccessStoriesSlider/imgs/zoomin.webp";

/*import runningPanther from "./graphic/running-panther-thumb.png";
import blockChainSystem from "./graphic/blockchain-system-thumb.png";
import landingPage from "./graphic/landing-page-thumb.png";
import engineersBook from "./graphic/engineer's-book-app-icon-thumb.png";
import helloDribbble from "./graphic/hello-dribbble-thumb.png";
import toggle from "./graphic/toggle-thumb.png";
import virtualMeeting from "./graphic/virtual-meeting-thumb.png";
import skyLine from "./graphic/skyline-thumb.png";
import travelApp from "./graphic/travel-app-thumb.png";
import virtualReality from "./graphic/virtual-reality-thumb.png";
import atWorkIllustration from "./graphic/at-work-illustration-thumb.png";
import foodDeliveryGraphic from "./graphic/food-delivery-thumb.png";
import cryptocurrencyConverter from "./graphic/cryptocurrency-converter-thumb.png";
import comet from "./graphic/comet-thumb.png";
import insuranceService from "./graphic/insurance-service-thumb.png";
import cryptocurrencyWallet from "./graphic/cryptocurrency-wallet-thumb.png";
import iBeaconBasedApp from "./graphic/ibeacon-based-app-thumb.png";
import reviewPage from "./graphic/review-page-interaction-thumb.png";
import trackingDataWebsite from "./graphic/tracking-data-website-animation-thumb.png";
import tenKFollowers from "./graphic/10k-followers-thumb.png";
import deliveryBoy from "./graphic/delivery-boy-thumb.png";
import iBeaconBasedAppImage from "./imgs/ibeacon-deals-and-rewards-app-thumb.jpg";
import crewLove from "./imgs/crew-love---social-network-app-thumb.jpg";
import matchMaking from "./imgs/match-making-app-thumb.jpg";
import ExchangeImg from "./imgs/exchange-thumb.jpg";
import gearGallery from "./imgs/geargallery-app-thumb-thumb.png";
import MosquePrayerImg from "./imgs/mosque-prayer-app-thumb.png";
import socialShoppingImg from "./imgs/social-shopping-app-thumb.jpg";
import BeaconbasedDealsImg from "./imgs/beacon-based-deals-app-thumb.jpg";
import idobbs from "./web/idobbs-thumb.png";
import serviceCalibrate from "./web/service-calibrate-thumb.png";
import skipcr from "./web/skipcr-thumb.png";
import ufmradio from "./web/ufmradio-thumb.png";
import petroindustrial from "./web/petroindustrial-thumb.png";
import breadAndBagels from "./web/bread-and-bagels-thumb.png";
import descubrebelleza from "./web/descubrebelleza-thumb.png";
import legacyRental from "./web/legacy-rental-thumb.png";
import zhi from "./web/zhi-thumb.png";
import donzendekbed from "./web/donzendekbed-thumb.png";
import mercedesSalazar from "./web/mercedes-salazar-thumb.png";
import homesByHairs from "./web/homes-by-haris-thumb.png";
import justFab from "./web/justfab-thumb.png";
import dafiti from "./web/dafiti-thumb.png";
import ezolar from "./imgs/solar-marketplace-app-thumb.jpg";
import favr from "./imgs/favr-leverage-your-network-thumb.jpg";
import groceryDeal from "./imgs/grocery-deals-app-thumb.png";*/
/*import creatorImg from "./imgs/creator-thumb.jpg";*/
/*import SellyhooImg from "./imgs/sellyhoo-thumb.jpg";*/
/*import journeyImg from "./imgs/journey-thumb.jpg";*/
/*import trackingImg from "./imgs/in-kind-tracking-and-management-app-thumb.jpg";*/
/*import foodlanderImg from "./imgs/foodlander-thumb.jpg";*/
/*import ikydenImg from "./imgs/ikyden-thumb.jpg";*/
/*import tripieImg from "./imgs/tripie-thumb.jpg";*/
/*import routineImg from "./imgs/7-minute-morning-routine-thumb.jpg";*/
/*import spordyImg from "./imgs/spordy-thumb.jpg";*/
/*import zohariImg from "./imgs/zoharii-thumb.jpg";*/
/*import sipImg from "./imgs/sip-thumb.jpg";*/
/*import simpleDraftImg from "./imgs/simple-draft-thumb.jpg";*/
/*import oceanFM from "./imgs/ocean-fm-thumb.jpg";*/
/*import planit from "./imgs/planit-style-thumb.jpg";*/
/*import mathWorksImg from "./imgs/math-works-thumb.jpg";*/
/*import moneygerImg from "./imgs/moneyger-thumb.jpg";*/
/*import calibrate from "./imgs/service-calibrate-thumb.jpg";*/
/*import drawingApp from "./imgs/drawing-app-thumb.png";*/
/*import uhp from "./imgs/uhp-alarm-clock-pro-thumb.jpg";*/
/*import uviteThumb from "./imgs/uvite-thumb.jpg";*/
/*import cardiac from "./imgs/fitness-&-cardiac-health-app-thumb.jpg";*/
/*import SnaportsImg from "./web/snaports---sports-social-media-app-thumb.png";
import syntax from "./web/syntaxtechs-thumb.png";
import shoedazzle from "./web/shoedazzle-thumb.png";
import bounch from "./web/bounch_thumbnail_1.jpg";
import moneyApp from "./graphic/money_transfer.jpg";
import FastMoney from "./graphic/money_banking_app.jpg";
import BrokerWeb from "./graphic/broking_website.jpg";
import PaymentApp from "./graphic/payment_app.jpg";
import HealthApp from "./graphic/health_app.jpg";
import CosmeticsApp from "./graphic/cosmetics_app.jpg";
import BeautyApp from "./graphic/beauty_app_desig.jpg";
import YogaWeb from "./graphic/yoga_web.jpg";
import FitnessApp from "./graphic/fitness_app.jpg";
import YogaDashboard from "./graphic/yoga_dashboard.jpg";
import FoodApp from "./graphic/food_app.jpg";
import FoodApp1 from "./graphic/food_app01.jpg";
import FoodApp2 from "./graphic/food_app02.jpg";
import DrinkApp from "./graphic/great_Jamaican_app_beer.jpg";
import OnDemand from "./graphic/on_demand_service_app.jpg";
import TaxiBooking from "./graphic/on_demand_taxi_booking_app.jpg";
import FurnitureWeb from "./graphic/furniture_landing_page.jpg";
import CityBuilding from "./graphic/city_building_app.jpg";
import RealEstate from "./graphic/real_estate_website.jpg";
import TravelApp1 from "./graphic/travel_app.jpg";
import TravelApp2 from "./graphic/travel_web_platform_1.jpg";
import FlightDashboard from "./graphic/flight_web_dashboard.jpg";
import TravelAppOnboarding from "./graphic/travel_app_design.jpg";
import TravelWebPlatform from "./graphic/travel_web_platform.jpg";
import TravelWeb from "./graphic/travel-web-design001.jpg";
import Sports1 from "./graphic/sport_app.jpg";
import IplApp from "./graphic/ipl_cricket_app.jpg";
import Fifacup from "./graphic/football_world_cup_app.jpg";
import Pitchers from "./graphic/pitchers_app.jpg";
import IplRedesign from "./graphic/ipl-app-redesign.jpg";
import EducationApp from "./graphic/my_new_education_app.jpg";
import KidsLearning from "./graphic/kids_learning_app.jpg";
import EducationDashboard from "./graphic/kids_app.jpg";
import BookWallet from "./graphic/books_wallet_app.jpg";
import MovieApp from "./graphic/movie_app_design01.jpg";
import VoiceRecordApp from "./graphic/voice_recorder_app.jpg";*/
/*import MyWeddingVenue from "./imgs/my-wedding-venue-finder-thumb.jpg";*/
/*import UBlast from "./imgs/ublast-thumb.jpg";*/
/*import MobileBreadAndbagles from "./imgs/bread-and-bagels-thumb.jpg";*/
/*import UFM from "./imgs/ufm-thumb.jpg";*/
/*import Myshul from "./imgs/my-shul-app-thumb.jpg";*/
/*import DroodDrive from "./imgs/drood---drive-thrus-nearby-thumb.jpg";*/
/*import UrbanaThumb from  "./imgs/urbana-thumb.jpg";*/
/*import AirStyle from "./imgs/airstylz-thumb.jpg"*/
/*import Textto from  "./imgs/texttou-thumb.jpg";*/
/*import EverlyDesign from "./imgs/everly-design-thumb.jpg";*/
/*import pigiWeb from "./imgs/pigi-web.png";*/
// import fairSwap from "./imgs/fairswap---currency-exchange-app-thumb.png";
// import knockiz from "./imgs/knockiz-deals-app-thumb-thumb.jpeg";
// import cameraStore from "./imgs/camera-store-app-thumb.png";
// import Exchange from "../../pages/PortfolioDetail/Exchange/Exchange";
// import KermisWeert from "../../pages/PortfolioDetail/KermisWeert/KermisWeert";
// import Helponymous from "../../pages/PortfolioDetail/Helponymous/Helponymous";
// import Pigi from "../../pages/PortfolioDetail/Pigi/Pigi";
// import Creator from "../../pages/PortfolioDetail/Creator/Creator";
// import Spordy from "../../pages/PortfolioDetail/Spordy/Spordy";
// import Sellyhoo from "../../pages/PortfolioDetail/Sayhello/Sellyhoo";
// import Foodlander from "../../pages/PortfolioDetail/Foodlander/Foodlander";
// import iKYDen from "../../pages/PortfolioDetail/iKYDen/iKYDen";
// import Tripie from "../../pages/PortfolioDetail/Tripie/Tripie";
// import Urbana from "../../pages/PortfolioDetail/Urbana/Urbana"
// import TextToU from "../../pages/PortfolioDetail/TextToU/TextToU";
// import Moneyger from "../../pages/PortfolioDetail/Moneyger/moneyger";
// import EnneaApp from "../../pages/PortfolioDetail/EnneaApp/EnneaApp";

// const url = 'https://www.mindinventory.com/img/portfolio-round-images'

const portfolio = [
  {
    title: "NFT Marketplace Design",
    industry: "ecommerce",
    img: MoneyMakerthumbnail,
    url: "/portfolio/money-maker.php",
    type: "success-story",
    component: "./pages/PortfolioDetail/MoneyMaker/MoneyMaker",
    solution: "fitness-solutions",
    png: moneymakerroundImg,
    webp: moneymakerroundImg,
    isPortfolio: true,
  },
  {
    title: "On Demand Telehealth App for Android and iOS",
    industry: "Lifestyle", // TODO
    img: purebeautythumbnail,
    url: "/portfolio/beauty-product-online-store.php",
    type: "success-story",
    component: "./pages/PortfolioDetail/PureBeauty/PureBeauty",
    solution: "",
    png: purebeautyroundImg,
    webp: purebeautyroundImg,
    isPortfolio: true,
  },
  // {
  //     title: "On Demand Telehealth App for Android and iOS",
  //     industry: "Lifestyle", // TODO
  //     img: purebeautythumbnail,
  //     url: "/portfolio/beauty-product-online-store.php",
  //     type: "success-story",
  //     component: './pages/PortfolioDetail/CaseStudy/CaseStudy',
  //     solution: "",
  //     png: purebeautyroundImg,
  //     webp: purebeautyroundImg,
  //     isPortfolio: true,
  // },
  {
    title: "On Demand Telehealth App for Android and iOS",
    industry: "Lifestyle", // TODO
    img: purebeautythumbnail,
    url: "/portfolio/plant-shop.php",
    type: "success-story",
    component: "./pages/PortfolioDetail/PlantShop/PlantShop",
    solution: "",
    png: plantShopCircleThumb_png,
    webp: plantShopCircleThumb,
    isPortfolio: true,
  },
  {
    title: "On Demand Telehealth App for Android and iOS",
    industry: "Lifestyle", // TODO
    img: purebeautythumbnail,
    url: "/portfolio/travel-agency.php",
    type: "success-story",
    component: "./pages/PortfolioDetail/TravelAgency/TravelAgency",
    solution: "",
    png: travelAgencyCircleThumb_png,
    webp: travelAgencyCircleThumb,
    isPortfolio: true,
  },
  {
    title: "On Demand Telehealth App for Android and iOS",
    industry: "Lifestyle", // TODO
    img: purebeautythumbnail,
    url: "/portfolio/dudepins.php",
    type: "success-story",
    component: "./pages/PortfolioDetail/Dudepins/Dudepins",
    solution: "",
    png: dudepinsCircleThumb_png,
    webp: dudepinsCircleThumb,
    isPortfolio: true,
  },
  {
    title: "On Demand Telehealth App for Android and iOS",
    industry: "Lifestyle", // TODO
    img: purebeautythumbnail,
    url: "/portfolio/parental-control-app.php",
    type: "success-story",
    component: "./pages/PortfolioDetail/ParentalControlApp/ParentalControlApp",
    solution: "",
    png: parentalControlAppCircleThumb_png,
    webp: parentalControlAppCircleThumb,
    isPortfolio: true,
  },
  {
    title: "On Demand Telehealth App for Android and iOS",
    industry: "Lifestyle", // TODO
    img: purebeautythumbnail,
    url: "/portfolio/medsure.php",
    type: "success-story",
    component: "./pages/PortfolioDetail/Medsure/Medsure",
    solution: "",
    png: medsureCircleThumb_png,
    webp: medsureCircleThumb,
    isPortfolio: true,
  },
  {
    title: "On Demand Telehealth App for Android and iOS",
    industry: "Lifestyle", // TODO
    img: purebeautythumbnail,
    url: "/portfolio/smart-home-nuemorphism.php",
    type: "success-story",
    component:
      "./pages/PortfolioDetail/SmartHomeNuemorphism/SmartHomeNuemorphism",
    solution: "",
    png: smartHomeNuemorphismCircleThumb_png,
    webp: smartHomeNuemorphismCircleThumb,
    isPortfolio: true,
  },
  {
    title: "On Demand Telehealth App for Android and iOS",
    industry: "Lifestyle", // TODO
    img: purebeautythumbnail,
    url: "/portfolio/salon-app.php",
    type: "success-story",
    component: "./pages/PortfolioDetail/SalonApp/SalonApp",
    solution: "",
    png: salonAppCircleThumb_png,
    webp: salonAppCircleThumb,
    isPortfolio: true,
  },
  {
    title: "On Demand Telehealth App for Android and iOS",
    industry: "Lifestyle", // TODO
    img: purebeautythumbnail,
    url: "/portfolio/time-management.php",
    type: "success-story",
    component: "./pages/PortfolioDetail/TimeManagement/TimeManagement",
    solution: "",
    png: timeManagementCircleThumb_png,
    webp: timeManagementCircleThumb,
    isPortfolio: true,
  },
  {
      title: "On Demand Telehealth App for Android and iOS",
      industry: "Lifestyle", // TODO
      img: purebeautythumbnail,
      url: "/portfolio/task-management.php",
      type: "success-story",
      component: './pages/PortfolioDetail/TaskManagement/TaskManagement',
      solution: "",
      png: taskManagementCircleThumb_png,
      webp: taskManagementCircleThumb,
      isPortfolio: true,
  },
  {
      title: "On Demand Telehealth App for Android and iOS",
      industry: "Lifestyle", // TODO
      img: purebeautythumbnail,
      url: "/portfolio/stock-market.php",
      type: "success-story",
      component: './pages/PortfolioDetail/StockMarket/StockMarket',
      solution: "",
      png: stockMarketCircleThumb_png,
      webp: stockMarketCircleThumb,
      isPortfolio: true,
  },
  {
      title: "On Demand Telehealth App for Android and iOS",
      industry: "Lifestyle", // TODO
      img: purebeautythumbnail,
      url: "/portfolio/headphone-controller-app.php",
      type: "success-story",
      component: './pages/PortfolioDetail/HeadphoneControllerApp/HeadphoneControllerApp',
      solution: "",
      png: headphoneControllerAppCircleThumb_png,
      webp: headphoneControllerAppCircleThumb,
      isPortfolio: true,
  },
  {
      title: "On Demand Telehealth App for Android and iOS",
      industry: "Lifestyle", // TODO
      img: purebeautythumbnail,
      url: "/portfolio/ticket-booking-app.php",
      type: "success-story",
      component: './pages/PortfolioDetail/TicketBookingApp/TicketBookingApp',
      solution: "",
      png: ticketBookingAppCircleThumb_png,
      webp: ticketBookingAppCircleThumb,
      isPortfolio: true,
  },

  /*{
        title: "Golfer Community App",
        industry: "Sports",
        img: abWorkSuccessStory,
        url: "/portfolio/golfer-community-app.php",
        type: "success-story",
        component: './pages/PortfolioDetail/GPGolf/GPGolf',
        solution: "Sports-solutions",
        png: `${url}/png/gpgolf-round.png`,
        webp: `${url}/webp/gpgolf-round.webp`,
        isPortfolio: true,
    },
    {
        title: "Ridesharing App for Android and iPhone",
        industry: "Travel",
        img: 'https://www.mindinventory.com/img/thumbnail-images/qaar.webp',
        url: "/portfolio/ridesharing-app.php",
        type: "success-story",
        component: './pages/PortfolioDetail/RideSharingApp/RideSharingApp',
        solution: "travel-app-development",
        png: `${url}/png/ridesharing.png`,
        webp: `${url}/webp/ridesharing.webp`,
        isPortfolio: true,
    },
    {
        title: "Fantasy Sports Mobile App for Cricket",
        industry: "Lifestyle", // TODO
        img: 'https://mindinventory.com/img/thumbnail-images/crickfantsy.webp',
        url: "/portfolio/fantasy-sports-app.php",
        type: "success-story",
        component: './pages/PortfolioDetail/CricFantasy/CricFantasy',
        solution: "educational-app-development-solutions",
        png: `${url}/png/cric-fantasy.png`,
        webp: `${url}/webp/cric-fantasy.webp`,
        isPortfolio: true,
    },
    {
        title: "Food Delivery App",
        industry: "Food and Drink",
        img: foodDeliverySuccessStory,
        url: "/portfolio/food-delivery-app.php",
        type: "success-story",
        component: './pages/PortfolioDetail/BhojNew/BhojNew',
        solution: "food-delivery-solutions",
        png: `${url}/png/bhoj.png`,
        webp: `${url}/webp/bhoj.webp`,
        isPortfolio: true,
    },
    {
        title: "Ennea",
        industry: "Lifestyle",
        img: enneaAppSuccessStory,
        url: "/portfolio/enneaapp.php",
        type: "success-story",
        component: './pages/PortfolioDetail/Ennea/Ennea',
        solution: "educational-app-development-solutions",
        png: `${url}/png/ennea.png`,
        webp: `${url}/webp/ennea.webp`,
        isPortfolio: true,
    },
    {
        title: "eCommerce Camera Store App for iPhone",
        industry: "Lifestyle", // TODO
        img: 'https://www.mindinventory.com/img/thumbnail-images/cam4sell.webp',
        url: "/portfolio/ecommerce-camera-store-app.php",
        type: "success-story",
        component: './pages/PortfolioDetail/Cam4Sell/CamForSell',
        solution: "educational-app-development-solutions",
        png: `${url}/png/cam4sell.png`,
        webp: `${url}/webp/cam4sell.webp`,
        isPortfolio: true,
    },
    {
        title: "Copomap App",
        industry: "Deal",
        img: CopomapImg,
        url: "/portfolio/coupons-deals-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/copomap/Copomap',
        solution: "ecommerce-solutions",
        png: `${url}/png/daily-deals.png`,
        webp: `${url}/webp/daily-deals.webp`,
        isPortfolio: true,
    },
    {
        title: "Zoomer App",
        industry: "Business",
        img: pengeapod,
        url: "/portfolio/kids-elearning-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Zoomer/Zoomer',
        solution: "business-application-development",
        png: `${url}/png/zoomer.png`,
        webp: `${url}/webp/zoomer.webp`,
        isPortfolio: true,
    },
    {
        title: "Rosita",
        industry: "Lifestyle",
        img: enneaAppSuccessStory,
        url: "/portfolio/wellness-app-for-elderly.php",
        type: "success-story",
        component: './pages/PortfolioDetail/Rosita/Rosita',
        solution: "educational-app-development-solutions",
        png: `${url}/png/rosita.png`,
        webp: `${url}/webp/rosita.webp`,
        isPortfolio: true,
    },
    {
        title: "DentalCare App",
        industry: "Healthcare",
        img: "",
        url: "/portfolio/dentalcare-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/DentalCare/DentalCare',
        solution: "entertainment-app-development",
        png: `${url}/png/dental-care-app.png`,
        webp: `${url}/webp/dental-care-app.webp`,
        isPortfolio: true,
    },
    {
        title: "Ignite App",
        industry: "Deal",
        img: "",
        url: "/portfolio/gym-fitness-tracking-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Ignite/Ignite',
        solution: "fitness-solutions",
        png: `${url}/png/ignite.png`,
        webp: `${url}/webp/ignite.webp`,
        isPortfolio: true,
    },
    {
        title: "Bus Booking App",
        industry: "Deal",
        img: "",
        url: "/portfolio/bus-ticket-booking-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/MyBus/MyBus',
        solution: "fitness-solutions",
        png: `${url}/png/bus-ticket-booking-app.png`,
        webp: `${url}/webp/bus-ticket-booking-app.webp`,
        isPortfolio: true,
    },
    {
        title: "Pigi",
        industry: "Fitness",
        img: "",
        url: "/portfolio/health-supplement-app.php",
        type: "success-story",
        component: './pages/PortfolioDetail/Pigi/Pigi',
        solution: "fitness-solutions",
        png: `${url}/png/pigi.png`,
        webp: `${url}/webp/pigi.webp`,
        isPortfolio: true,
    },
    {
        title: "Helponymous App",
        industry: "",
        img: "",
        url: "/portfolio/helponymous.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Helponymous/Helponymous',
        solution: "fitness-solutions",
        png: `${url}/png/helponymous.png`,
        webp: `${url}/webp/helponymous.webp`,
        isPortfolio: true,

    },
    {
        title: "Smashers Collectors App",
        industry: "Entertainment",
        img: "",
        url: "/portfolio/smashers-collectors-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Smashers/Smashers',
        solution: "entertainment-app-development",
        png: `${url}/png/smashers.png`,
        webp: `${url}/webp/smashers.webp`,
        isPortfolio: true,
    },
    {
        title: "Cryptocurrency App",
        industry: "Fintech",
        img: "",
        url: "/portfolio/cryptocurrency.php",
        type: "mobile",
        component: '',
        solution: "fintech-solutions",
        png: `${url}/png/cryptocurrency.png`,
        webp: `${url}/webp/cryptocurrency.webp`,
        isPortfolio: false,
    },
    {
        title: "Daily Expense App",
        industry: "Fintech",
        img: "",
        url: "/portfolio/daily-expense.php",
        type: "mobile",
        component: '',
        solution: "fintech-solutions",
        png: `${url}/png/daily-expense.png`,
        webp: `${url}/webp/daily-expense.webp`,
        isPortfolio: false,
    },*/

  /*{
        title: "Ab Workout App",
        industry: "Fitness",
        img: abWorkSuccessStory,
        url: "/portfolio/ab-workout-app.php",
        type: "success-story",
        component: './pages/PortfolioDetail/Ab6ppStory/Ab6ppStory',
        solution: "fitness-solutions",
        png: `${url}/png/6pp.png`,
        webp: `${url}/webp/6pp.webp`,
        isPortfolio: true,
    },*/
  /*{
        title: "On Demand Telehealth App for Android and iOS",
        industry: "Lifestyle", // TODO
        img: 'https://mindinventory.com/img/thumbnail-images/waitless.webp',
        url: "/portfolio/on-demand-telehealth-app.php",
        type: "success-story",
        component: './pages/PortfolioDetail/OnDemandTelehealthApp/OnDemandTelehealthApp',
        solution: "educational-app-development-solutions",
        png: `${url}/png/waitless.png`,
        webp: `${url}/webp/waitless.webp`,
        isPortfolio: true,
    },
    {
        title: "The Baya App",
        industry: "Real Estate",
        img: BayaAppImg,
        url: "/portfolio/real-estate-app.php",
        type: 'mobile',
        component: './pages/PortfolioDetail/BayaApp/BayaApp',
        solution: 'real-estate-app-development',
        png: `${url}/png/baya.png`,
        webp: `${url}/webp/baya.webp`,
        isPortfolio: true,
    },
    {
        title: "Pangea Pod App",
        industry: "Business",
        img: pengeapod,
        url: "/portfolio/hotel-management-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Pangea/Pangea',
        solution: "business-application-development",
        png: `${url}/png/pangea.png`,
        webp: `${url}/webp/pangea.webp`,
        isPortfolio: true,
    },*/
  // {
  //     title: "Online Photo Printing Solution",
  //     industry: "Retail",
  //     img: ZoominSuccessStory,
  //     url: "/portfolio/online-photo-printing-solution.php",
  //     type: "success-story",
  //     component: './pages/PortfolioDetail/ZoominNew/ZoominNew',
  //     solution: "food-delivery-solutions",
  //     png: `${url}/png/zoomin.png`,
  //     webp: `${url}/webp/zoomin.webp`,
  // },

  // {
  //     title: "Dopamine",
  //     industry: "Lifestyle",
  //     img: enneaAppSuccessStory,
  //     url: "/portfolio/bitcoin-crypto-price-tracker.php",
  //     type: "success-story",
  //     component: './pages/PortfolioDetail/Dopamine/Dopamine',
  //     solution: "educational-app-development-solutions",
  //     png: `${url}/png/dopamine.png`,
  //     webp: `${url}/webp/dopamine.webp`,
  // },

  /*
        {
            slug: `#`,
            png: 'https://www.mindinventory.com/img/portfolios/png/cryptocurrency.png',
            webp: 'https://www.mindinventory.com/img/portfolios/webp/cryptocurrency.webp',
            imageAlt: '',
            class: 'col-md-6',
            imageBox: 'big-img-box',
            title: 'Cryptocurrency App',
            description: 'Coming Soon...',
            isPortfolio: false,
            size: 6,
        },*/
  // {
  //     title: "Books Selling Platform",
  //     industry: "E-commerce",
  //     img: "",
  //     url: "/portfolio/books-selling-app.php",
  //     type: "success-story",
  //     component: './pages/PortfolioDetail/Lunar/Lunar',
  //     solution: "E-commerce-solutions",
  //     png: `${url}/png/lunar-round.png`,
  //     webp: `${url}/webp/lunar-round.webp`,
  // },
  // {
  //     title: "DentalCare App",
  //     industry: "Health & Fitness",
  //     img: DentalCareImg,
  //     url: "/portfolio/dentalcare.php",
  //     type: 'mobile',
  //     component: './pages/PortfolioDetail/DentalCare/DentalCare',
  //     solution: 'healthcare-solutions'
  // },
  // {
  //     title: "MyBus - Bus Ticket Booking App",
  //     industry: "Travel",
  //     img: mybus,
  //     url: "/portfolio/bus-ticket-booking-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/Mybus/MyBus',
  //     solution: "travel-app-development"
  // },
  // {
  //     title: "Realtime Running App",
  //     industry: "Fitness",
  //     img: realtimeRunning,
  //     url: "/portfolio/realtime-running-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/RealTimeRunning/RealTimeRunning',
  //     solution: "fitness-solutions"
  // },
  // {
  //     title: "EdSmart - Education App",
  //     industry: "Educational",
  //     img: edSmartImg,
  //     url: "/portfolio/edsmart-education-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/EdSmart/EdSmart',
  //     solution: "educational-app-development-solutions"
  // },
  // {
  //     title: "KermisWeert",
  //     industry: "Travel",
  //     img: KermisWeertImg,
  //     url: "/portfolio/kermisweert.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/KermisWeert/KermisWeert',
  //     solution: "ecommerce-solutions"
  // },
  // {
  //     title: "Engage Music App",
  //     industry: "Entertainment",
  //     img: engageMusic,
  //     url: "/portfolio/engage-music-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/EnageApp/EnageApp',
  //     solution: "entertainment-app-development"
  // },
  // {
  //     title: "Pigi",
  //     industry: "Shopping",
  //     img: pigiSuccessStory,
  //     url: "/portfolio/pigi.php",
  //     type: 'success-story',
  //     component: './pages/PortfolioDetail/Pigi/Pigi',
  //     solution: 'ecommerce-solutions'
  // },
  // {
  //     title: "Find A to Z",
  //     industry: "Lifestyle, Travel & Real Estate",
  //     img: findAtoZImg,
  //     url: "/portfolio/find-a-to-z.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/FindAToZ/FindAToZ',
  //     solution: "business-application-development"
  // },
  // {
  //     title: "FriendSongs App",
  //     industry: "Entertainment",
  //     img: friendSongsApp,
  //     url: "/portfolio/friendsongs.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/FriendsSongs/FriendsSongs',
  //     solution: "entertainment-app-development"
  // },
  // {
  //     title: "VoxPop-Survey and Opinion App",
  //     industry: "Utility",
  //     img: voxpop,
  //     url: "/portfolio/voxpop-survey-opinion-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/VoxPop/VoxPop',
  //     solution: "on-demand-app-development"
  // },
  // {
  //     title: "WeAct",
  //     industry: "Social",
  //     img: weact,
  //     url: "/portfolio/weact.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/Weact/Weact',
  //     solution: "ecommerce-solutions"
  // },
  // {
  //     title: "Yuuud-Social Network App",
  //     industry: "Social & Entertainment",
  //     img: yuuud,
  //     url: "/portfolio/yuuud-social-network-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/Yuuud/Yuuud',
  //     solution: "social-media-app-development"
  // },
  // {
  //     title: "Travel Social Network App",
  //     industry: "Social",
  //     img: travelSocialNetworkImg,
  //     url: "/portfolio/travel-social-network-app.php",
  //     type: 'mobile',
  //     component: './pages/PortfolioDetail/TravelSocialNetworking/TravelSocialNetworking',
  //     solution: 'travel-app-development'
  // },
  // {
  //     title: "Send Postcards App",
  //     industry: "Travel",
  //     img: sendPostCards,
  //     url: "/portfolio/send-postcards-app.php",
  //     type: 'mobile',
  //     component: './pages/PortfolioDetail/SendPostcardsApp/SendPostcards',
  //     solution: 'social-media-app-development'
  // },
  // {
  //     title: "Smashers Collectors App",
  //     industry: "Entertainment",
  //     img: smashers,
  //     url: "/portfolio/smashers-collectors-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/Smashers/Smashers',
  //     solution: "entertainment-app-development"
  // },
  // {
  //     title: "threeBuzz",
  //     industry: "Lifestyle & Business",
  //     img: threebuzz,
  //     url: "/portfolio/threebuzz.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/ThreeBuzz/ThreeBuzz',
  //     solution: "business-application-development"
  // },
  // {
  //     title: "Video Sharing App",
  //     industry: "Social",
  //     img: videoSharingImg,
  //     url: "/portfolio/video-sharing-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/VideoSharing/VideoSharing',
  //     solution: "social-media-app-development"
  // },

  // {
  //     title: "Helponymous",
  //     industry: "Health & Fitness",
  //     img: helponymousImg,
  //     url: "/portfolio/helponymous.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/Helponymous/HelponymousDetail',
  //     solution: "healthcare-solutions"
  // },
  /*{
        title: "Solar Marketplace App",
        industry: "Business",
        img: ezolar,
        url: "/mobile-portfolio/solar-marketplace-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Ezolar/Ezolar',
        solution: "business-application-development"
    },*/
  /*{
        title: "Shoedazzle",
        industry: "Shopping",
        img: shoedazzle,
        url: "https://www.shoedazzle.com/",
        type: "web",
        component: null,
        solution: "ecommerce-solutions"
    },*/
  /*{
        title: "Drawing App",
        industry: "Educational",
        img: drawingApp,
        url: "/mobile-portfolio/drawing-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/StrategyCreator/StrategyCreator',
        solution: "educational-app-development-solutions"
    },*/
  /*{
        title: "idobbs",
        industry: "GPS/Maps",
        img: idobbs,
        url: "http://www.rurwebsolutions.com/iDobbs/",
        type: "web",
        component: null,
        solution: "travel-app-development"
    },
    {
        title: "Service Calibrate",
        industry: "Deal, GPS/Maps",
        img: serviceCalibrate,
        url: "http://www.servicecalibrate.com/",
        type: "web",
        component: null,
        solution: "business-application-development"
    },
    {
        title: "skipcr",
        industry: "Healthcare",
        img: skipcr,
        url: "http://www.skipcr.com/",
        type: "web",
        component: null,
        solution: "healthcare-solutions"
    },
    {
        title: "ufmradio",
        industry: "",
        img: ufmradio,
        url: "http://ufmradio.com/",
        type: "web",
        component: null,
        solution: "entertainment-app-development"
    },
    {
        title: "Petroindustrial",
        industry: "",
        img: petroindustrial,
        url: "http://www.petroind.com/default/",
        type: "web",
        component: null,
        solution: "business-application-development"
    },
    {
        title: "Bread And Bagels",
        industry: "",
        img: breadAndBagels,
        url: "http://breadandbagels.com/",
        type: "web",
        component: null,
        solution: ""
    },
    {
        title: "descubrebelleza",
        industry: "",
        img: descubrebelleza,
        url: "http://www.lanskybros.com/",
        type: "web",
        component: null,
        solution: "ecommerce-solutions"
    },
    {
        title: "Legacy Rental",
        industry: "",
        img: legacyRental,
        url: "http://www.legacyrentalservices.com/",
        type: "web",
        component: null,
        solution: ""
    },
    {
        title: "zhi",
        industry: "",
        img: zhi,
        url: "https://www.zhitea.com/",
        type: "web",
        component: null,
        solution: "food-delivery-solutions"
    },
    {
        title: "Donzendekbed",
        industry: "",
        img: donzendekbed,
        url: "https://www.donzendekbed.nl/",
        type: "web",
        component: null,
        solution: "healthcare-solutions"
    },
    {
        title: "Mercedes Salazar",
        industry: "",
        img: mercedesSalazar,
        url: "https://int.mercedessalazar.com/",
        type: "web",
        component: null,
        solution: "ecommerce-solutions"
    },
    {
        title: "Homes By Haris",
        industry: "",
        img: homesByHairs,
        url: "http://www.homesbyharis.com/",
        type: "web",
        component: null,
        solution: "real-estate-app-development"
    },
    {
        title: "justfab",
        industry: "Lifestyle",
        img: justFab,
        url: "http://www.justfab.com/",
        type: "web",
        component: null,
        solution: "ecommerce-solutions"
    },*/
  /*{
        title: "Ocean FM",
        industry: "News & Entertainment",
        img: oceanFM,
        url: "/mobile-portfolio/ocean-fm.php",
        type: "mobile",
        component: './pages/PortfolioDetail/OceanFm/OceanFm',
        solution: "entertainment-app-development"
    }*/
  /*{
        title: "Planlt Style",
        industry: "Social & Lifestyle",
        img: planit,
        url: "/mobile-portfolio/planlt-style.php",
        type: "mobile",
        component: './pages/PortfolioDetail/PlanltStyle/PlanitStyle',
        solution: "social-media-app-development"
    }*/
  /*{
        title: "Math Works!",
        industry: "Educational & Kids Learning",
        img: mathWorksImg,
        url: "/mobile-portfolio/math-works.php",
        type: "mobile",
        component: './pages/PortfolioDetail/MathWorks/mathWorks',
        solution: "social-media-app-development"
    }*/
  /*{
        title: "Moneyger",
        industry: "Finance & Utility",
        img: moneygerImg,
        url: "/mobile-portfolio/moneyger.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Moneyger/moneyger',
        solution: "social-media-app-development"
    }*/
  /*{
        title: "My Wedding Venue Finder",
        industry: "GPS/Maps, Events & Lifestyle",
        img: MyWeddingVenue,
        url: "/mobile-portfolio/my-wedding-venue-finder.php",
        type: "mobile",
        component: './pages/PortfolioDetail/MyWeddingVenueFinder/MyWeddingVenueFinder',
        solution: "entertainment-app-development"
    }*/
  /*{
        title: "Uhp alarm Clock Pro",
        industry: "Utility",
        img: uhp,
        url: "/mobile-portfolio/uhp-alarm-clock-pro.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Uhp/Uhp',
        solution: "on-demand-app-development"
    }*/
  /*{
        title: "Creator",
        industry: "Social",
        img: creatorImg,
        url: "/mobile-portfolio/creator.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Creator/Creator',
        solution: "on-demand-app-development"
    }
    {
        title: "UBlast",
        industry: "Social",
        img: UBlast,
        url: "/mobile-portfolio/ublast.php",
        type: "mobile",
        component: './pages/PortfolioDetail/UBlast/UBlast',
        solution: "on-demand-app-development"
    }
    {
        title: "Spordy",
        industry: "Sports",
        img: spordyImg,
        url: "/mobile-portfolio/spordy.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Spordy/Spordy',
        solution: "on-demand-app-development"
    }
    {
        title: "Sellyhoo",
        industry: "Shopping, Lifestyle & Business",
        img: SellyhooImg,
        url: "/mobile-portfolio/sellyhoo.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Sayhello/Sellyhoo',
        solution: "on-demand-app-development"
    }
    {
        title: "Bread And Bagels",
        industry: "food delivery solutions",
        img: MobileBreadAndbagles,
        url: "/mobile-portfolio/bread-and-bagels.php",
        type: "mobile",
        component: './pages/PortfolioDetail/BreadAndBagels/BreadAndBagels',
        solution: "on-demand-app-development"
    }
    {
        title: "Journey - social network",
        industry: "Social",
        img: journeyImg,
        url: "/mobile-portfolio/journey-social-network.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Journey/Journey',
        solution: "on-demand-app-development"
    }
    {
        title: "Foodlander",
        industry: "Lifestyle & Food and Drink",
        img: foodlanderImg,
        url: "/mobile-portfolio/foodlander.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Foodlander/Foodlander',
        solution: "on-demand-app-development"
    }
    {
        title: "iKYDen",
        industry: "Utility, Events, Business & Travel",
        img: ikydenImg,
        url: "/mobile-portfolio/ikyden.php",
        type: "mobile",
        component: './pages/PortfolioDetail/iKYDen/iKYDen',
        solution: "on-demand-app-development"
    }
    {
        title: "uvite",
        industry: "Events & Social",
        img: uviteThumb,
        url: "/mobile-portfolio/uvite.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Uvite/Uvite',
        solution: "entertainment-app-development"
    }
    {
        title: "UFM",
        industry: "Utility, News & Entertainment",
        img: UFM,
        url: "/mobile-portfolio/ufm.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Ufm/ufm',
        solution: "on-demand-app-development"
    }
    {
        title: "Service Calibrate",
        industry: "Deal & GPS/Maps",
        img: calibrate,
        url: "/mobile-portfolio/service-calibrate.php",
        type: "mobile",
        component: './pages/PortfolioDetail/ServiceCalibrate/ServiceCalibrate',
        solution: "business-application-development"
    }
    {
        title: "Tripie",
        industry: "GPS/Maps & Travel",
        img: tripieImg,
        url: "/mobile-portfolio/tripie.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Tripie/Tripie',
        solution: "on-demand-app-development"
    }
    {
        title: "7 Minute Morning Routine",
        industry: "Fitness",
        img: routineImg,
        url: "/mobile-portfolio/7-minute-morning-routine.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Routine/Routine',
        solution: "on-demand-app-development"
    }
    {
        title: "My Shul App",
        industry: "Community, Events & Lifestyle",
        img: Myshul,
        url: "/mobile-portfolio/my-shul-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/MyShul/MyShul',
        solution: "on-demand-app-development"
    }
    {
        title: "Drood - Drive Thrus Nearby",
        industry: "GPS/Maps & Lifestyle",
        img: DroodDrive,
        url: "/mobile-portfolio/drood-drive-thrus-nearby.php",
        type: "mobile",
        component: './pages/PortfolioDetail/DroodDriveThrusNearby/DroodDriveThrusNearby',
        solution: "on-demand-app-development"
    }
    {
        title: "In-kind Tracking and Management App",
        industry: "Business",
        img: trackingImg,
        url: "/mobile-portfolio/In-kind-tracking-management-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Tracking/Tracking',
        solution: "on-demand-app-development"
    }
    {
        title: "Urbana",
        industry: "Lifestyle & Real Estate",
        img: UrbanaThumb,
        url: "/mobile-portfolio/urbana.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Urbana/Urbana',
        solution: "on-demand-app-development"
    }
    {
        title: "Zoharii",
        industry: "Shopping & Business",
        img: zohariImg,
        url: "/mobile-portfolio/zoharii.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Zohari/Zohari',
        solution: "on-demand-app-development"
    }
    {
        title: "Exchange",
        industry: "Finance & Business",
        img: ExchangeImg,
        url: "/mobile-portfolio/exchange.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Exchange/Exchange',
        solution: "fintech-solutions"
    }
    {
        title: "AirStylz",
        industry: "Lifestyle & Entertainment",
        img: AirStyle,
        url: "/mobile-portfolio/airstylz.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Airstylz/Airstylz',
        solution: "on-demand-app-development"
    }
    {
        title: "Favr-leverage your network",
        industry: "Social",
        img: favr,
        url: "/mobile-portfolio/favr.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Favr/Favr',
        solution: "social-media-app-development"
    }
    {
        title: "Fitness & Cardiac Health App",
        industry: "Fitness",
        img: cardiac,
        url: "/mobile-portfolio/fitness-cardiac-health-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/WorldFitnessLevel/WorldFirnessLevel',
        solution: "fitness-solutions"
    }
    {
        title: "SIP",
        industry: "Food and Drink",
        img: sipImg,
        url: "/mobile-portfolio/sip.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Sip/Sip',
        solution: "fitness-solutions"
    }
    {
        title: "TextToU",
        industry: "Business",
        img: Textto,
        url: "/mobile-portfolio/texttou.php",
        type: "mobile",
        component: './pages/PortfolioDetail/TextToU/TextToU',
        solution: "on-demand-app-development"
    }
    {
        title: "Simple Draft",
        industry: "Sports",
        img: simpleDraftImg,
        url: "/mobile-portfolio/simple-draft.php",
        type: "mobile",
        component: './pages/PortfolioDetail/SimpleDraft/SimpleDraft',
        solution: "fitness-solutions"
    }
    {
        title: "Everly Design",
        industry: "Shopping & Lifestyle",
        img: EverlyDesign,
        url: "/mobile-portfolio/everly-design.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Everlydesign/Everlydesign',
        solution: "on-demand-app-development"
    }*/
  /*{
        title: "Travel app",
        industry: "Travel",
        img: travelApp,
        url: "https://dribbble.com/shots/4555698-Virtual-Meeting",
        type: "graphic",
        component: null,
        solution: "travel-app-development"
    },
    {
        title: "Virtual Reality",
        industry: "Entertainment",
        img: virtualReality,
        url: "https://dribbble.com/shots/4498958-Virtual-Reality",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "At work illustration",
        industry: "Business",
        img: atWorkIllustration,
        url: "https://dribbble.com/shots/4542798-At-work-illustration",
        type: "graphic",
        component: null,
        solution: "business-application-development"
    },
    {
        title: "Food Delivery",
        industry: "Food and Drink",
        img: foodDeliveryGraphic,
        url: "https://www.behance.net/gallery/52354069/Food-Delivery",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "Cryptocurrency Converter",
        industry: "Finance",
        img: cryptocurrencyConverter,
        url: "https://dribbble.com/shots/4360796-Cryptocurrency-Converter",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "Comet",
        industry: "GPS/Maps",
        img: comet,
        url: "https://dribbble.com/shots/4382375-Comet",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "Insurance service",
        industry: "GPS/Maps & Business",
        img: insuranceService,
        url: "https://dribbble.com/shots/4391401-Insurance-service-landing-page",
        type: "graphic",
        component: null,
        solution: "business-application-development"
    },
    {
        title: "Cryptocurrency Wallet",
        industry: "Finance",
        img: cryptocurrencyWallet,
        url: "https://dribbble.com/shots/4226153-Cryptocurrency-Wallet-Development",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "iBeacon based app",
        industry: "IBeacon",
        img: iBeaconBasedApp,
        url:
            "https://dribbble.com/shots/4510835-iBeacon-based-app-to-get-exciting-offers",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "Review Page Interaction",
        industry: "Utility",
        img: reviewPage,
        url: "https://dribbble.com/shots/4332677-Review-Page-Interaction",
        type: "graphic",
        component: null,
        solution: "on-demand-app-development"
    },
    {
        title: "Tracking data website animation",
        industry: "Business",
        img: trackingDataWebsite,
        url: "https://dribbble.com/shots/4406031-Tracking-data-website-animation",
        type: "graphic",
        component: null,
        solution: "business-application-development"
    },
    {
        title: "10K Followers",
        industry: "Social",
        img: tenKFollowers,
        url: "https://dribbble.com/shots/4578622-10K-Followers",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "Running Panther",
        industry: "Fitness",
        img: runningPanther,
        url: "https://dribbble.com/shots/3608040-Running-Panther",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "Landing Page",
        industry: "Business",
        img: landingPage,
        url: "https://dribbble.com/shots/4569893-Landing-Page-Website",
        type: "graphic",
        component: null,
        solution: "business-application-development"
    },
    {
        title: "Engineer's Book App Icon",
        industry: "Business",
        img: engineersBook,
        url: "https://dribbble.com/shots/3904054-Engineer-s-Book-App-Icon",
        type: "graphic",
        component: null,
        solution: "business-application-development"
    },
    {
        title: "Delivery Boy",
        industry: "Business & Food and Drink",
        img: deliveryBoy,
        url: "https://www.mindinventory.com/graphic-portfolio/delivery-boy.php",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "Hello Dribbble",
        industry: "Community",
        img: helloDribbble,
        url: "https://dribbble.com/shots/4560458-Hello-Dribbble",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "Blockchain System",
        industry: "Finance & Business",
        img: blockChainSystem,
        url: "https://dribbble.com/shots/4136301-Blockchain-System",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "Toggle",
        industry: "Entertainment",
        img: toggle,
        url: "https://dribbble.com/shots/3695246-Toggle",
        type: "graphic",
        component: null,
        solution: ""
    },
    {
        title: "Virtual Meeting",
        industry: "Business",
        img: virtualMeeting,
        url: "https://dribbble.com/shots/4555698-Virtual-Meeting",
        type: "graphic",
        component: null,
        solution: "business-application-development"
    },
    {
        title: "Skyline",
        industry: "Business",
        img: skyLine,
        url: "https://dribbble.com/shots/3931535-Skyline",
        type: "graphic",
        component: null,
        solution: "business-application-development"
    },*/
  /*{
        title: "Dafiti",
        industry: "Shopping",
        img: dafiti,
        url: "https://www.dafiti.com.br/",
        type: "web",
        component: null,
        solution: "ecommerce-solutions"
    },*/
  /*{
        title: "Money App",
        industry: "Fintech",
        img: moneyApp,
        url: "https://dribbble.com/shots/10574196-Money-App",
        type: "graphic",
        component: null,
        solution: "fintech-solutions"
    },
    {
        title: "Fast-Money",
        industry: "Fintech",
        img: FastMoney,
        url: "https://dribbble.com/shots/10140108-Fast-Money-Banking-App",
        type: "graphic",
        component: null,
        solution: "fintech-solutions"
    },
    {
        title: "Brokers Website",
        industry: "Fintech",
        img: BrokerWeb,
        url: "https://dribbble.com/shots/7029516-Brokers-Website",
        type: "graphic",
        component: null,
        solution: "fintech-solutions"
    },
    {
        title: "Payment app",
        industry: "Fintech",
        img: PaymentApp,
        url: "https://dribbble.com/shots/7921707-Payment-app",
        type: "graphic",
        component: null,
        solution: "fintech-solutions"
    },
    {
        title: "Health App",
        industry: "Healthcare",
        img: HealthApp,
        url: "https://dribbble.com/shots/10028692-Health-App",
        type: "graphic",
        component: null,
        solution: "healthcare-solutions"
    },
    {
        title: "Cosmetic app",
        industry: "Healthcare",
        img: CosmeticsApp,
        url: "https://dribbble.com/shots/9399328-Cosmetic-app",
        type: "graphic",
        component: null,
        solution: "healthcare-solutions"
    },
    {
        title: "Beauty app",
        industry: "Healthcare",
        img: BeautyApp,
        url: "https://dribbble.com/shots/9139336-Beauty-app",
        type: "graphic",
        component: null,
        solution: "healthcare-solutions"
    },
    {
        title: "Yoga Studio Website",
        industry: "Fitness",
        img: YogaWeb,
        url: "https://dribbble.com/shots/10524833-Yoga-Studio-Website",
        type: "graphic",
        component: null,
        solution: "fitness-solutions"
    },
    {
        title: "Fitness app",
        industry: "Fitness",
        img: FitnessApp,
        url: "https://dribbble.com/shots/9551517-Fitness-app-dark",
        type: "graphic",
        component: null,
        solution: "fitness-solutions"
    },
    {
        title: "Yoga App Dashboard Design",
        industry: "Fitness",
        img: YogaDashboard,
        url: "https://dribbble.com/shots/8365090-Dashboard-Design-Yoga",
        type: "graphic",
        component: null,
        solution: "fitness-solutions"
    },
    {
        title: "Food Delivery App",
        industry: "Food & Drink",
        img: FoodApp,
        url: "https://dribbble.com/shots/9515135-Food-App",
        type: "graphic",
        component: null,
        solution: "food-delivery-solutions"
    },
    {
        title: "Food Delivery App",
        industry: "Food & Drink",
        img: FoodApp1,
        url: "https://dribbble.com/shots/8132399-Food-app",
        type: "graphic",
        component: null,
        solution: "food-delivery-solutions"
    },
    {
        title: "Food Delivery App",
        industry: "Food & Drink",
        img: FoodApp2,
        url: "https://dribbble.com/shots/6832505-Food-Menu",
        type: "graphic",
        component: null,
        solution: "food-delivery-solutions"
    },
    {
        title: "The Great Jamaican Beer App",
        industry: "Food & Drink",
        img: DrinkApp,
        url: "https://dribbble.com/shots/6767550-The-Great-Jamaican-App-BEER",
        type: "graphic",
        component: null,
        solution: "food-delivery-solutions"
    },
    {
        title: "On Demand Service App",
        industry: "On Demand",
        img: OnDemand,
        url: "https://dribbble.com/shots/9023485-On-Demand-Service-App",
        type: "graphic",
        component: null,
        solution: "on-demand-app-development"
    },
    {
        title: "Taxi Booking Service",
        industry: "On Demand",
        img: TaxiBooking,
        url:
            "https://dribbble.com/shots/6576193-MyCab-On-demand-Taxi-Booking-App-Landing-Page",
        type: "graphic",
        component: null,
        solution: "on-demand-app-development"
    },
    {
        title: "Furniture Website",
        industry: "Real Estate",
        img: FurnitureWeb,
        url: "https://dribbble.com/shots/6675580-Furniture-Landing-Page",
        type: "graphic",
        component: null,
        solution: "real-estate-app-development"
    },
    {
        title: "City Building App",
        industry: "Real Estate",
        img: CityBuilding,
        url: "https://dribbble.com/shots/10437042-City-Building-App",
        type: "graphic",
        component: null,
        solution: "real-estate-app-development"
    },
    {
        title: "Real Estate Web",
        industry: "Real Estate",
        img: RealEstate,
        url: "https://dribbble.com/shots/6864196-Real-Estate",
        type: "graphic",
        component: null,
        solution: "real-estate-app-development"
    },
    {
        title: "Travel App",
        industry: "Travel",
        img: TravelApp1,
        url: "https://dribbble.com/shots/9124509-Travel-App",
        type: "graphic",
        component: null,
        solution: "travel-app-development"
    },
    {
        title: "Travel App",
        industry: "Travel",
        img: TravelApp2,
        url: "https://dribbble.com/shots/9059377-Travel-App",
        type: "graphic",
        component: null,
        solution: "travel-app-development"
    },
    {
        title: "Flight Booking Dashboard",
        industry: "Travel",
        img: FlightDashboard,
        url: "https://dribbble.com/shots/6838348-Flight-Web",
        type: "graphic",
        component: null,
        solution: "travel-app-development"
    },
    {
        title: "Travel App",
        industry: "Travel",
        img: TravelAppOnboarding,
        url: "https://dribbble.com/shots/9432056-Travel-App",
        type: "graphic",
        component: null,
        solution: "travel-app-development"
    },
    {
        title: "Travel Web Platform",
        industry: "Travel",
        img: TravelWebPlatform,
        url: "https://dribbble.com/shots/9071281-Travel-Web-Platform-Design",
        type: "graphic",
        component: null,
        solution: "travel-app-development"
    },
    {
        title: "Travel Website",
        industry: "Travel",
        img: TravelWeb,
        url: "https://dribbble.com/shots/9407543-Travel-website",
        type: "graphic",
        component: null,
        solution: "travel-app-development"
    },
    {
        title: "Sports App",
        industry: "Sports",
        img: Sports1,
        url: "https://dribbble.com/shots/6554421-Sport",
        type: "graphic",
        component: null,
        solution: "sports-app-development"
    },
    {
        title: "IPL App",
        industry: "Sports",
        img: IplApp,
        url: "https://dribbble.com/shots/6219762-IPL-App",
        type: "graphic",
        component: null,
        solution: "sports-app-development"
    },
    {
        title: "Fifa Cup 2018",
        industry: "Sports",
        img: Fifacup,
        url: "https://dribbble.com/shots/4693767-Fifa-Cup-2018",
        type: "graphic",
        component: null,
        solution: "sports-app-development"
    },
    {
        title: "HangUp App",
        industry: "Sports",
        img: Pitchers,
        url: "https://dribbble.com/shots/10215836-HangUp-App",
        type: "graphic",
        component: null,
        solution: "sports-app-development"
    },
    {
        title: "Ipl app redesign",
        industry: "Sports",
        img: IplRedesign,
        url: "https://dribbble.com/shots/4630899-Ipl-app-redesign",
        type: "graphic",
        component: null,
        solution: "sports-app-development"
    },
    {
        title: "Ipl app redesign",
        industry: "Education",
        img: EducationApp,
        url: "https://dribbble.com/shots/6947480-Education-App",
        type: "graphic",
        component: null,
        solution: "educational-app-development-solutions"
    },
    {
        title: "Kids Learning App",
        industry: "Education",
        img: KidsLearning,
        url: "https://dribbble.com/shots/7134012-Kids-Learn",
        type: "graphic",
        component: null,
        solution: "educational-app-development-solutions"
    },
    {
        title: "Educational App Dashboard",
        industry: "Education",
        img: EducationDashboard,
        url: "https://dribbble.com/shots/7317509-EDSmart",
        type: "graphic",
        component: null,
        solution: "educational-app-development-solutions"
    },
    {
        title: "Books Wallet App",
        industry: "Entertainment",
        img: BookWallet,
        url: "https://dribbble.com/shots/9341089-Books-Wallet-App",
        type: "graphic",
        component: null,
        solution: "entertainment-app-development"
    },
    {
        title: "Movie App",
        industry: "Entertainment",
        img: MovieApp,
        url: "https://dribbble.com/shots/9196401-Movie-App",
        type: "graphic",
        component: null,
        solution: "entertainment-app-development"
    },
    {
        title: "Voice Recorder App",
        industry: "Entertainment",
        img: VoiceRecordApp,
        url: "https://dribbble.com/shots/9197331-Voice-Recorder-App",
        type: "graphic",
        component: null,
        solution: "entertainment-app-development"
    },*/
  /*{
        title: "iBeacon Deals and Rewards App",
        industry: "IBeacon & Deal",
        img: iBeaconBasedAppImage,
        url: "/mobile-portfolio/ibeacon-deals-and-rewards-app.php",
        type: 'mobile',
        component: './pages/PortfolioDetail/iBeaconDeals/iBeaconDeals',
        solution: 'fintech-solutions'
    },*/
  /*{
        title: "Syntax Technologies",
        industry: "Educational",
        img: syntax,
        url: "https://www.syntaxtechs.com/",
        type: "web",
        component: null,
        solution: "educational-app-development-solutions"
    },*/
  /*{
        title: "Beacon based Deals App",
        industry: "Educational",
        img: BeaconbasedDealsImg,
        url: "/mobile-portfolio/beacon-based-deals-app.php",
        type: 'mobile',
        component: './pages/PortfolioDetail/BeaconbasedDeals/BeaconbasedDeals',
        solution: 'deal'
    },*/
  /*{
        title: "Gear Gallery - Selfie Sharing App",
        industry: "Social",
        img: gearGallery,
        url: "/mobile-portfolio/geargallery-selfie-sharing-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/GearGallerySelfieSharing/GearGallerySelfieSharing',
        solution: "social-media-app-development"
    },*/
  /*{
        title: "Grocery Deals App",
        industry: "Deal & Food and Drink",
        img: groceryDeal,
        url: "/mobile-portfolio/grocery-deals-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/GroceryDealsApp/GroceryDealsApp',
        solution: "on-demand-app-development"
    },*/
  /*{
        title: "Match Making App",
        industry: "Community & Social",
        img: matchMaking,
        url: "/mobile-portfolio/match-making-app.php",
        type: 'mobile',
        component: './pages/PortfolioDetail/MatchMakingApp/MatchMaking',
        solution: 'social-media-app-development'
    },*/
  /*{
        title: "Social Shopping App",
        industry: "Shopping",
        img: socialShoppingImg,
        url: "/mobile-portfolio/social-shopping-app.php",
        type: 'mobile',
        component: './pages/PortfolioDetail/SocialShopping/SocialShopping',
        solution: 'ecommerce-solutions'
    },*/
  /*{
        title: "Crew Love - Social Network App",
        industry: "Social",
        img: crewLove,
        url: "/mobile-portfolio/crew-love-social-network-app.php",
        type: 'mobile',
        component: './pages/PortfolioDetail/CrewLove/CrewLove',
        solution: 'ecommerce-solutions'
    },*/
  // {
  //     title: "Fairswap-Currency Exchange App",
  //     industry: "Finance",
  //     img: fairSwap,
  //     url: "/mobile-portfolio/fairswap-currency-exchange-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/FairSwap/FairSwap',
  //     solution: "fintech-solutions"
  // },
  /*{
        title: "Mosque Prayer App",
        industry: "Travel",
        img: MosquePrayerImg,
        url: "/mobile-portfolio/mosque-prayer-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/MosquePrayer/MosquePrayer',
        solution: "travel"
    },*/
  /*{
        title: "Snaports - Sports Social Media App",
        industry: "Sports",
        img: SnaportsImg,
        url: "/mobile-portfolio/snaports-sports-social-media-app.php",
        type: "mobile",
        component: './pages/PortfolioDetail/Snaports/Snaports',
        solution: "sports-app-development"
    },*/
  // {
  //     title: "Ab Workout App",
  //     industry: "Fitness",
  //     img: abWorkSuccessStory,
  //     url: "/success-story/6pp.php",
  //     type: "success-story",
  //     component: './pages/PortfolioDetail/AbWorkout/AbWorkout',
  //     solution: "fitness-solutions"
  // },
  // {
  //     title: "Coupons & Deals App",
  //     industry: "Deal",
  //     img: coupon,
  //     url: "/mobile-portfolio/coupons-deals-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/CouponsDealsApp/coupons-deals-app',
  //     solution: "ecommerce-solutions"
  // },
  // {
  //     title: "Camera Store App",
  //     industry: "Shopping",
  //     img: cameraStore,
  //     url: "/mobile-portfolio/camera-store-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/CameraStore/CameraStore',
  //     solution: "ecommerce-solutions"
  // },
  // {
  //     title: "Knockiz - Local Deals App",
  //     industry: "Business",
  //     img: knockiz,
  //     url: "/mobile-portfolio/knockiz-local-deals-app.php",
  //     type: "mobile",
  //     component: './pages/PortfolioDetail/Knockiz/Knockiz',
  //     solution: "business-application-development"
  // },
  /*{
        title: "Pigi",
        industry: "Shopping",
        img: pigiWeb,
        url: "https://pigi.in/",
        type: "web",
        component: null,
        solution: "ecommerce-solutions"
    },*/
  // {
  //     title: "EnneaApp",
  //     industry: "Lifestyle",
  //     img: enneaAppSuccessStory,
  //     url: "/success-story/enneaapp.php",
  //     type: "success-story",
  //     component: './pages/PortfolioDetail/EnageApp/EnageApp',
  //     solution: "educational-app-development-solutions"
  // },
  /*{
        title: "Bounch",
        industry: "Business",
        img: bounch,
        url: "https://bounch.com/",
        type: "web",
        component: null,
        solution: "business-application-development"
    },*/
];

export { portfolio };
