import React from "react";
import { withRouter } from "react-router-dom";

const Context = React.createContext();

class Provider extends React.Component {
  state = {
    darkMode: false,
    windowWidth: window.innerWidth,

    // cursor
    touchDevice: false,
    innerCursor: "",
    outerCursor: "",
    outerCursorBox: "",
    clientX: -100,
    clientY: -100,
    showCursor: false,
    cursorState: "",
    cursorLabel: "",
    cursorImg: "",
    cursorWebp: "",
    targetBox: "",

    // decoration
    count: 0,
    speed: 10,
    circleFlash: false,

    // menu
    isMenuOpen: false,

    // dark header in about us page
    darkHeader: false,

    // no footer in contact us page
    showFooter: true
  };
  componentDidMount() {
    // this.setState({
    //   innerCursor: document.querySelector(".inner-cursor"),
    //   outerCursor: document.querySelector(".outer-cursor"),
    //   outerCursorBox: document
    //     .querySelector(".outer-cursor")
    //     .getBoundingClientRect()
    // });

    window.addEventListener("scroll", this.handleDecorationMove);
    window.addEventListener("resize", this.handleResize);

    // Detect whether it is a touch device or not
    function is_touch_device() {
      return (
        "ontouchstart" in window ||
        navigator.MaxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    if (!is_touch_device()) {
      this.setState({ touchDevice: false });
    }
    if (is_touch_device()) {
      this.setState({ touchDevice: true });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleDecorationMove);
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleDarkMode = result => {
    this.setState({ darkMode: result });
  };
  handleMouseMove = e => {
    if (!this.state.touchDevice) {
      this.setState({
        clientX: e.clientX,
        clientY: e.clientY
      });
    }
  };
  handleMouseEnter = (e, cursorState, cursorLabel, cursorImg = '',  cursorWebp = '') => {
    if (cursorState === "stuck") {
      const targetBox = e.target.getBoundingClientRect();
      this.setState({ targetBox, cursorState });
    }
    if (cursorState === "big") {
      this.setState({ cursorState, cursorLabel });
    }
    if (cursorState === "link") {
      this.setState({ cursorState });
    }
    if (cursorState === "img") {
      this.setState({ cursorState, cursorImg, cursorWebp });
    }
  };
  handleMouseLeave = () => {
    this.setState({ targetBox: "", cursorState: "", cursorLabel: "" });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
      this.handleMouseLeave();
    }
  }

  handleDecorationMove = () => {
    this.setState(
      {
        count: this.state.count + this.state.speed,
        circleFlash: true
      },
      () => {
        setTimeout(() => {
          this.setState({ circleFlash: false });
        }, 1000);
      }
    );
    if (this.state.count > 100 || this.state.count < 0) {
      this.setState({ speed: this.state.speed * -1 });
    }
  };

  handleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  handleDarkHeader = headerTheme => {
    this.setState({ darkHeader: headerTheme === "dark" });
  };

  handleShowFooter = () => {
    this.setState({ showFooter: !this.state.showFooter });
  };

  render() {
    const {
      handleDarkMode,
      handleMouseMove,
      handleMouseEnter,
      handleMouseLeave,
      handleDecorationMove,
      handleMenu,
      handleDarkHeader,
      handleShowFooter
    } = this;
    return (
      <Context.Provider
        value={{
          ...this.state,
          handleDarkMode,
          handleMouseMove,
          handleMouseEnter,
          handleMouseLeave,
          handleDecorationMove,
          handleMenu,
          handleDarkHeader,
          handleShowFooter
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

const Consumer = Context.Consumer;

Provider = withRouter(Provider);
export { Provider, Consumer, Context };
