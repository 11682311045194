import React, {useState, useRef} from "react";
import {Link} from "react-router-dom";
import "./MiMobileHeader.scss";
import NavServices from "./NavServices";
import MiMobileDropdownItem from "./MiMobileDropdownItem";
import NavSolutions from "./NavSolutions";
import NavHire from "./NavHire";

const MiMobileDropdown = () => {
    const [tab, setTab] = useState(null);
    const mobileMenuTopRef = useRef()

    const data = [
        {
            title: "Services",
            id: 0,
            isDropdown: true,
            target: "Services",
            to: "/services.php",
            tab: <NavServices/>
        },
        {
            title: "Solutions",
            id: 1,
            isDropdown: true,
            target: "Solutions",
            to: "/solutions.php",
            tab: <NavSolutions/>
        },
        {
            title: "Hire Resources",
            id: 2,
            isDropdown: true,
            target: "Hire Resources",
            to: "/hire-dedicated-developers.php",
            tab: <NavHire/>
        },
        {
            title: "Portfolio",
            id: 3,
            isDropdown: false,
            target: "Portfolio",
            to: "/all-portfolios.php"
        },
        {
            title: "About",
            id: 4,
            isDropdown: false,
            target: "About",
            to: "/about-us.php"
        },
        {
            title: "Testimonials",
            id: 5,
            isDropdown: false,
            target: "Testimonials",
            to: "/testimonial.php"
        }
    ];

    const scrollview = () => {
        mobileMenuTopRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }

    return (
        <div className="mobile-menu-dropdown">
            <div className="container-fluid" ref={mobileMenuTopRef}>
                <div className="row">
                    {data.map((item, index) => (
                        <MiMobileDropdownItem
                            key={index}
                            item={item}
                            setTab={setTab}
                            isActive={item.id === tab}
                            scrollview={scrollview}
                        />
                    ))}
                    <div className="col-12 d-flex justify-content-center">
                        <Link to='/contact-us.php' className="mobile-menu-contact-btn">Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MiMobileDropdown;
