import React, {useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import { Consumer } from "../../Context";
import "./MiMobileHeader.scss";
import './MiHeader.scss'
import LogoW from "../../oceanshark-logo.svg";
import MenuIcon from "../../assets/icons/menu-new-icon.svg";
import MiMobileDropdown from "./MiMobileDropdown";

const MiMobileHeader = () => {
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false)
  const handleMenu = () => setShowMenu(!showMenu)
  useEffect(() => {
    setShowMenu(false)
  }, [location]);
  return (
      <Consumer>
        {context => (
            <>
              <div className="d-flex justify-content-between align-items-center mi-header-mobile">
                <Link to="/" className="pl-30 logo d-block">
                  <img
                      loading="lazy"
                      src={LogoW}
                      alt="Oceanshark Logo"
                      width={180}
                      height={28}
                  />
                </Link>
                <button className="menu-button"  onClick={handleMenu}
                >
                  <img
                      loading="lazy"
                      src={MenuIcon}
                      alt="menu-icon"
                      width={24}
                      height={24}
                  />
                </button>
              </div>
              {showMenu && window.innerWidth < 1080 &&  <MiMobileDropdown /> }
            </>
        )}
      </Consumer>
  );
};

export default MiMobileHeader;
