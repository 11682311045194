import React from "react";
import { Link } from "react-router-dom";
import { Consumer } from "../../Context";

class SolutionsTab extends React.Component {
  state = {
    height: 0
  };
  componentDidMount() {
    const height = document
      .getElementById("solutionsTab")
      .getBoundingClientRect().height;
    this.setState({ height });
  }
  render() {
    return (
      <Consumer>
        {context => (
          <div className="tab-detail" style={{ height: this.state.height }}>
            <div className="row mx-0 mt-5" id="solutionsTab">
              <div className="col-12 col-md-6 col-xl-4 order-md-1 order-xl-0 px-0 mb-5 mb-xl-0">
                <div className="overflow-hidden">
                  <h6 className="uppercase-text list-title">
                    <p>Enterprise Services</p>
                  </h6>
                  <ul className="ul-list">
                    <li>
                      <Link
                        to="/cloud-computing-solutions.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Cloud Computing Solutions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/enterprise-app-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Enterprise Mobility Solutions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product-engineering-services.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Product Engineering
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/app-development-for-startups.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Startup Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/devops-solutions.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        DevOps Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/digital-transformation-services.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Digital Transformation
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 order-md-0 order-xl-0 px-0 mb-5 mb-xl-0">
                <div className="overflow-hidden">
                  <h6 className="uppercase-text list-title">
                    <p>Solutions we provide</p>
                  </h6>
                  <ul className="ul-list">
                    <li>
                      <Link
                        to="/fintech-solutions.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Fintech Solutions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/ecommerce-solutions.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Ecommerce Solutions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/healthcare-solutions.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Healthcare Solutions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/fitness-solutions.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Fitness Solutions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/food-delivery-solutions.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Food Delivery Solutions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/on-demand-app-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        On-demand Solutions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 order-md-2 order-xl-0 px-0">
                <div className="overflow-hidden">
                  <h6 className="uppercase-text list-title">
                    <p>Industries we serve</p>
                  </h6>
                  <ul className="ul-list">
                    <li>
                      <Link
                        to="/real-estate-app-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Real Estate
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/travel-app-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Travel
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/sports-app-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Sports
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/social-media-app-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Social Networking
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/business-application-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Business
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/educational-app-development-solutions.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        eLearning & Education
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/entertainment-app-development.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Media & Entertainment
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

export default SolutionsTab;
