import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {Provider} from "./Context";
import {BrowserRouter as Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import 'jquery';

export const history = createBrowserHistory();
ReactDOM.render(
    <Router history={history}>
        <Provider>
            <App/>
        </Provider>
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
