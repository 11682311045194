import React from "react";
import { Link } from "react-router-dom";
import { Consumer } from "../../Context";

class ExtensionTab extends React.Component {
  state = {
    height: 0
  };
  componentDidMount() {
    const height = document
      .getElementById("extentionTab")
      .getBoundingClientRect().height;
    this.setState({ height });
  }
  render() {
    return (
      <Consumer>
        {context => (
          <div className="tab-detail" style={{ height: this.state.height }}>
            <div className="row mx-0 mt-5" id="extentionTab">
              <div className="col-12 col-md-6 col-xl-4 px-0 mb-5 mb-xl-0">
                <div className="overflow-hidden">
                  <h6 className="uppercase-text list-title">
                    <p>Hire Mobile App Developers</p>
                  </h6>
                  <ul className="ul-list">
                    <li>
                      <Link
                        to="/hire-iphone-app-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire iPhone App Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-android-app-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Android App Developers
                      </Link>
                    </li>
                    {/*<li>
                      <Link
                        to="/hire-ipad-app-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire iPad App Developers
                      </Link>
                    </li>*/}
                    <li>
                      <Link
                        to="/hire-swift-developer.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Swift Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-ionic-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Ionic App Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-react-native-developer.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire React Native Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-flutter-app-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Flutter Developers
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 px-0 mb-5 mb-xl-0">
                <div className="overflow-hidden">
                  <h6 className="uppercase-text list-title">
                    <p>Hire JS Developers</p>
                  </h6>
                  <ul className="ul-list">
                    <li>
                      <Link
                        to="/hire-angularjs-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire AngularJS Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-reactjs-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire ReactJS Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-vuejs-developer.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Vue.js Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-node-js-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Node.js Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-full-stack-developer.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Full Stack Developers
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 px-0">
                <div className="overflow-hidden">
                  <h6 className="uppercase-text list-title">
                    <p>Hire Web Developers</p>
                  </h6>
                  <ul className="ul-list">
                    <li>
                      <Link
                        to="/hire-golang-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Golang Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-python-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Python Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/hire-php-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire PHP Developers
                      </Link>
                    </li>
                    {/*<li>
                      <Link
                        to="/hire-magento-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Magento Developers
                      </Link>
                    </li>*/}
                    {/*<li>
                      <Link
                        to="/hire-joomla-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Joomla Developers
                      </Link>
                    </li>*/}
                    {/*<li>
                      <Link
                        to="/hire-zend-developers.php"
                        onClick={context.handleMenu}
                        onMouseEnter={e => context.handleMouseEnter(e, "link")}
                        onMouseLeave={context.handleMouseLeave}
                      >
                        Hire Zend Developers
                      </Link>
                    </li>*/}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

export default ExtensionTab;
