import React from "react";
import "./Header.scss";
import Logo from "../../logo-mind-inventory.svg";
import LogoW from "../../logo-mind-inventory-w.svg";
import { Consumer } from "../../Context";
import {Link, withRouter} from "react-router-dom";
import GridLines from "../GridLines/GridLines";
import hiringImg from "./img/hiring.svg";
import arrowImg from "./img/right-arrow.svg";

const Header = (props) => {
  const { logoTheme,location } = props;
  const showHiringSection = location.pathname === "/";
  const slugs = ['/all-portfolios.php']
  return (
    <Consumer>
      {context => (
          <header className={context.darkHeader ? "dark-mode" : ""}>
          <div className="container-fluid">
            <div className="row mi_hiring_main">
              <div className="col-4 col-sm-2 p-0 offset-1 offset-lg-0">
                <Link
                  to="/"
                  className="logo d-block"
                  onMouseEnter={e => context.handleMouseEnter(e, "link")}
                  onMouseLeave={context.handleMouseLeave}
                  onClick={() => context.isMenuOpen && context.handleMenu()}
                >
                  <img loading='lazy'
                     src={logoTheme === "white" ? LogoW : Logo}
                    alt=""
                       width={140} height={50}
                  />
                </Link>
              </div>
              {
                showHiringSection &&
                <div className="d-none d-md-block col-md-4 offset-md-4 col-lg-4 offset-lg-6 col-xl-2 offset-xl-8">
                  <div className="mi_hiring">
                    <Link to="/careers.php" onMouseEnter={e => context.handleMouseEnter(e, "link")}
                          onMouseLeave={context.handleMouseLeave}
                          onClick={() => context.isMenuOpen && context.handleMenu()}>
                      <div className="media align-items-center">
                        <img loading='lazy' src={hiringImg} alt="hiring icon" className="h_images" width={36} height={32}/>
                        <div className="media-body">
                          <h4 className="text-white m-0">Looking for job?</h4>
                          <h5 className="mt-0 text-white m-0">We are Hiring</h5>
                        </div>
                        <img loading='lazy' src={arrowImg} alt="right" className="arrow_img" width={21} height={18}/>
                      </div>
                    </Link>
                  </div>
                </div>
              }
              {/*<CaseStudyHeader />*/}
            </div>
          </div>
          {!slugs.includes(location.pathname) && context.darkHeader && <GridLines />}
        </header>
      )}
    </Consumer>
  );
};

export default withRouter(Header);
