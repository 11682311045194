import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./MiMobileHeader.scss";
import "./MiHeader.scss";
import {ReactComponent as DownChevron} from "../../assets/icons/down-chevron.svg";


const MiMobileDropdownItem = ({
                                  item,
                                  isActive,
                                  setTab,
    scrollview
                              }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <>
                <div
                    className="mobile-menu-dropdown-item col-12 px-0 d-flex justify-content-between align-items-center">
                    {
                        item.to === '/blog/' &&
                        <a href={item.to} className="mobile-menu-dropdown-title">
                            {item.title}
                        </a>
                    }
                    {
                        item.to !== '/blog/' && <Link to={item.to && item.to}
                              className={`${open && isActive ? "mobile-menu-dropdown-title-active" : ""} mobile-menu-dropdown-title`}>{item.title}</Link>
                    }
                    {item.tab && (
                        <span
                            onClick={() => {
                                setTab(null);
                                setOpen(true);
                                setTab(item.id);
                                if (isActive) setOpen(!open)
                                scrollview()
                            }}
                            className="dropdown-arrow-block"
                        >
                <DownChevron
                    className={
                        open && isActive ? "chevron-primary" : "chevron-white"
                    }
                />
              </span>
                    )}
                </div>
                {open && isActive && item.tab && item.tab}
            </>
        </>
    );
};

export default MiMobileDropdownItem;
